import * as moment from 'moment';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { SwalService } from '../../../../services/swal.service';
import { TicketsService } from '../../../../services/tickets.service';
import { ChargesService } from '../../../../services/charges.service';
import { HelpersService } from '../../../../services/helpers.service';
var CloseComponent = /** @class */ (function () {
    function CloseComponent(formBuilder, broadcast, activeModal, tickets, swal, fromService, chargesService, helpersService, cdRef) {
        this.formBuilder = formBuilder;
        this.broadcast = broadcast;
        this.activeModal = activeModal;
        this.tickets = tickets;
        this.swal = swal;
        this.fromService = fromService;
        this.chargesService = chargesService;
        this.helpersService = helpersService;
        this.cdRef = cdRef;
        this.subscriptions = [];
        this.payment_types = environment.payment_types;
        this.payment = false;
        this.show_serial_number = false;
        this.credit_applied = 0;
        this.credit_remaining_amount = 0;
        this.whatsAppMessage = '';
        this.form = this.formBuilder.group({
            final_service_fee: [0],
            final_service_fee_reasons: ['', Validators.required],
            showSerialNumber: [false]
        });
    }
    CloseComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.data);
        this.subscriptions.push(this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'tickets-technitians-row-click':
                    _this.message = event.data;
                    break;
            }
        }));
        this.credit_remaining_amount = this.creditRemainingAmount(this.data.client.active_credit);
        this.fromService.setForm(this.form);
    };
    CloseComponent.prototype.ngAfterViewChecked = function () {
        var _this = this;
        this.cdRef.detectChanges();
        this.subscriptions.push(this.form.get('final_service_fee').valueChanges.subscribe(function (value) {
            var paymentTypesControl = _this.form.get('payment_types');
            if (value > 0) {
                _this.payment = true;
                if (!paymentTypesControl) {
                    _this.form.addControl('payment_types', new FormControl('', Validators.required));
                }
            }
            else {
                _this.payment = false;
                if (paymentTypesControl) {
                    _this.form.removeControl('payment_types');
                }
            }
        }));
    };
    CloseComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * closeTicket
     * Envia una petición a la API para cerrar completamente un ticket
     */
    CloseComponent.prototype.closeTicket = function () {
        var _this = this;
        var close_info = {
            swal_params: {
                swal_title: 'Monto igual a 0, no se realizara ningún cargo al cliente',
                text: '¿Estás seguro de cerrar el ticket?'
            },
            params: {
                status: 'closed',
                final_service_fee: this.form.controls.final_service_fee.value,
                final_service_fee_reasons: this.form.controls.final_service_fee_reasons.value,
                used_credit: false
            }
        };
        if (this.form.get('serial_number')) {
            Object.assign(close_info.params, { serial_number: this.form.get('serial_number').value });
        }
        if (this.form.get('payment_types')) {
            Object.assign(close_info.params, { payment_types: this.form.get('payment_types').value });
        }
        if (this.form.controls.final_service_fee.value > 0) {
            var total = this.form.controls.final_service_fee.value;
            if (this.credit_remaining_amount > 0) {
                var originalTotal = total;
                var totalWithCredit = total - this.credit_remaining_amount;
                total = (totalWithCredit > 0 && totalWithCredit <= 10) ? total : totalWithCredit;
                this.credit_applied = (total < originalTotal && total > 10) ? (originalTotal - totalWithCredit) : 0;
                if (this.credit_applied > 0) {
                    close_info.params.used_credit = true;
                }
            }
            total = total < 0 ? 0 : total;
            close_info.swal_params.swal_title = "Se efecutara un cargo al cliente por el monto de $ " + total + " pesos";
        }
        this.swal.warning({ title: close_info.swal_params.swal_title, text: close_info.swal_params.text }).then(function (result) {
            if (result.value) {
                _this.subscriptions.push(_this.tickets.setStatus(close_info.params, _this.data.id_tickets).subscribe(function (data) {
                    if (data.success) {
                        if (close_info.params.final_service_fee > 0) {
                            _this.showSuccessModal(_this.form.get('payment_types').value, data);
                        }
                        else {
                            _this.swal.success({ text: 'Ticket cerrado exitosamente' }).then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                    }
                    else {
                        _this.swal.error({ title: 'Ocurrió un error al actualizar los datos' });
                    }
                }));
            }
        });
    };
    /**
     * updateSerialNumber
     * Añade el campo para actualizar el número de serie.
     * @param event determina si el checkbox esta marcado o no
     */
    CloseComponent.prototype.updateSerialNumber = function (event) {
        var serial_number_form = this.form.get('serial_number');
        if (event.checked) {
            this.show_serial_number = true;
            var serial_number = this.data.client.serial_number;
            if (!serial_number_form) {
                this.form.addControl('serial_number', new FormControl('', Validators.required));
                this.form.get('serial_number').setValue(serial_number);
            }
        }
        else {
            this.show_serial_number = false;
            if (serial_number_form) {
                this.form.removeControl('serial_number');
            }
        }
    };
    /**
     * formatMessage
     * Genera un objeto con la info que mostrara el modal cuando se reciba la confirmación de la creación de una referencia en la API
     * esta info se mostrara mientras la referencia no expire.
     * @param resp respuesta devuelta por la API con la info de la referencia creada
     * @param payment_type tipo de referencia que se generara OXXO/SPEI
     * @returns objeto que el titulo y el mensaje que llevara el modal
     */
    CloseComponent.prototype.formatMessage = function (resp, payment_type) {
        var offline_msg = '<div>';
        var title_offline = '';
        var oxxo_message = 'Referencia generada exitosamente';
        var spei_message = 'CLABE generada exitosamente';
        var payment_info = resp.response.data.charges.data[0].payment_method;
        var expiration_date = moment(payment_info.expires_at * 1000).format('MMMM Do YYYY, h:mm:ss a');
        switch (payment_type) {
            case 'OXXO':
                offline_msg += "<a>" + payment_info.reference + "<a>";
                title_offline = oxxo_message;
                break;
            case 'SPEI':
                offline_msg = "<a>" + payment_info.clabe + "<a>";
                title_offline = spei_message;
                break;
        }
        offline_msg += '<br> <br>';
        offline_msg += '<a><strong>Fecha de expiración: </strong></a>';
        offline_msg += "<a>" + expiration_date + "<a>";
        offline_msg += '<\div>';
        return { title: title_offline, msg: offline_msg };
    };
    /**
     * creditRemainingAmount
     * Verifica si el cliente tiene credito valido para hacer un descuento
     * @param activeCredit objeto con la informacion de un credito valido.
     * @returns number con la funcion
     */
    CloseComponent.prototype.creditRemainingAmount = function (activeCredit) {
        var creditRemainingAmount = 0;
        if (activeCredit) {
            if (activeCredit.status && activeCredit.remaining_amount > 100) {
                creditRemainingAmount = activeCredit.remaining_amount;
            }
        }
        return creditRemainingAmount / 100;
    };
    CloseComponent.prototype.sendByWhatsApp = function () {
        this.helpersService.sendByWhatsapp(this.whatsAppMessage, this.data.client.phone);
    };
    /**
     * showSuccessModal
     * Muestra el modal de exito cuando una referencia OXXO|SPEI o cargo por tarjeta es efectuado exitosamente.
     * @param paymentType tipo de cargo que se realizo Tarjeta|OXXO|SPEI
     * @param resp unicamente se usa si el cargo fue un OXXO|SPEI para mostra el modal de la información de la referencia.
     */
    CloseComponent.prototype.showSuccessModal = function (paymentType, resp) {
        var _this = this;
        if (paymentType === 'CARD') {
            this.swal.success({ title: 'Transación efectuada exitosamente' }).then(function () {
                _this.activeModal.dismiss();
                _this.broadcast.reloadDataTable();
            });
        }
        else {
            var whatsAppButton = "<button type=\"button\" class=\"btn btn-success\" id=\"sendByWa\" title=\"Enviar por Whatsapp\">\n                                <i class=\"fa fa-whatsapp\"></i>\n                              </button>";
            this.offline_format = this.chargesService.formatMessage(resp, paymentType);
            this.whatsAppMessage = this.offline_format.copyText;
            var data = {
                title: this.offline_format.title,
                html: this.offline_format.msg + whatsAppButton,
                onBeforeOpen: function () {
                    var sendByWa = document.getElementById('sendByWa');
                    sendByWa.addEventListener('click', function () {
                        _this.sendByWhatsApp();
                    });
                }
            };
            this.swal.success(data).then(function () {
                _this.activeModal.dismiss();
                _this.broadcast.reloadDataTable();
            });
        }
    };
    Object.defineProperty(CloseComponent.prototype, "validToUpdateSerialNumber", {
        /**
         * validToUpdateSerialNumber
         * Determina si se mostrara el checkbox para actualizar el numero de serie de
         * un equipo asociado a un cliente.
         */
        get: function () {
            var typesInvalidForUpdate = ['installation', 'removal'];
            var isValid = !typesInvalidForUpdate.includes(this.data.type);
            return isValid;
        },
        enumerable: true,
        configurable: true
    });
    return CloseComponent;
}());
export { CloseComponent };
