import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { SalesDashboardService } from '../../../../services/sales-dashboard.service';
import { SwalService } from '../../../../services/swal.service';
var SalesMonthChartComponent = /** @class */ (function () {
    function SalesMonthChartComponent(formBuilder, swal, salesDashboard) {
        this.formBuilder = formBuilder;
        this.swal = swal;
        this.salesDashboard = salesDashboard;
        this.isLoadMonthChart = new EventEmitter();
        this.isLoad = true;
        this.barChartColors = [
            { backgroundColor: '#83b941' },
            { backgroundColor: '#16a4db' },
            { backgroundColor: '#ff843c' },
            { backgroundColor: '#77FF11' },
            { backgroundColor: '#00ffff' }
        ];
        this.months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        this.barChartOptions = {
            responsive: true,
            // We use these empty structures as placeholders for dynamic theming.
            scales: { xAxes: [{}], yAxes: [{}] },
            plugins: {
                plugins: {
                    datalabels: {
                        color: 'black',
                        labels: {
                            title: {
                                font: {
                                    weight: 'bold'
                                }
                            },
                            value: {
                                color: 'black'
                            }
                        }
                    }
                }
            }
        };
        this.barChartLabels = [];
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.barChartPlugins = [pluginDataLabels];
        this.labelsValue = [];
        this.barChartData = [];
        this.form = this.formBuilder.group({
            from: [],
            to: []
        });
    }
    SalesMonthChartComponent.prototype.ngOnInit = function () {
        this.fieldFrom = this.form.get('from');
        this.fieldTo = this.form.get('to');
        this.setChart();
    };
    SalesMonthChartComponent.prototype.search = function () {
        var fromSub = this.form.get('from').value;
        var toSub = this.form.get('to').value;
        var params = {
            from: fromSub,
            to: toSub
        };
        if (params.from && params.to) {
            this.setChart(params);
        }
        else {
            this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
        }
    };
    SalesMonthChartComponent.prototype.resetChart = function () {
        this.isLoad = true;
        var index = Object.keys(this.barChartLabels);
        for (var _i = 0, index_1 = index; _i < index_1.length; _i++) {
            var i = index_1[_i];
            this.barChartLabels[i] = '';
        }
        index = Object.keys(this.barChartData);
        for (var _a = 0, index_2 = index; _a < index_2.length; _a++) {
            var i = index_2[_a];
            this.barChartData[i].data = [];
            this.barChartData[i].label = '';
        }
    };
    SalesMonthChartComponent.prototype.setChart = function (dateRangeParams) {
        var _this = this;
        this.isLoad = true;
        this.labelsValue = [];
        var params = {
            name: 'etiqueta_de_origen'
        };
        this.salesDashboard.contactProperty(params).subscribe(function (res) {
            if (res.success) {
                var options = res.response.data.options;
                for (var _i = 0, options_1 = options; _i < options_1.length; _i++) {
                    var option = options_1[_i];
                    _this.labelsValue.push({ label: option.label, value: option.value });
                }
                _this.salesDashboard.salesByMonth(dateRangeParams).subscribe(function (resp) {
                    // getting labels                  
                    if (resp.success) {
                        var barChartLabelData = [];
                        var keys = Object.keys(resp.response).sort();
                        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                            var index = keys_1[_i];
                            if (index) {
                                var yearMonth = index.split('-');
                                var year = yearMonth[0];
                                var month = _this.months[parseInt(yearMonth[1], 10) - 1];
                                barChartLabelData.push(month + " " + year);
                            }
                            // this.barChartLabels = barChartLabelData;
                        }
                        _this.barChartLabels = barChartLabelData.slice();
                        // group by options
                        var barChartDataL = [];
                        var _loop_1 = function (labelValue) {
                            var countByData = [];
                            for (var _i = 0, keys_2 = keys; _i < keys_2.length; _i++) {
                                var index = keys_2[_i];
                                var arrayByLabel = resp.response[index].filter(function (contact) {
                                    return contact.etiqueta_origen.indexOf(labelValue.value) > -1;
                                });
                                countByData.push(arrayByLabel.length);
                            }
                            var data = { data: countByData, label: labelValue.label };
                            barChartDataL.push(data);
                        };
                        for (var _a = 0, _b = _this.labelsValue; _a < _b.length; _a++) {
                            var labelValue = _b[_a];
                            _loop_1(labelValue);
                        }
                        _this.barChartData = barChartDataL.slice();
                        // this.barChartData = barChartDataL;
                        _this.isLoad = false;
                        _this.isLoadMonthChart.emit(true);
                    }
                    else {
                        _this.swal.error({ title: 'Error recuperando los datos para la gráfica ventas por mes, intente de nuevo' });
                    }
                });
            }
        });
    };
    SalesMonthChartComponent.prototype.showInfoGraphic = function () {
        var message = 'How many closed won based on month leads generated filtered by lead type';
        this.swal.info(message);
    };
    return SalesMonthChartComponent;
}());
export { SalesMonthChartComponent };
