import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { chartParams } from '../../../model/shared-component.interface';
import { SwalService } from '../../../services/swal.service';

@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {
  @Input('data') data: chartParams;

  chartColors: Array<Color> = [
    { backgroundColor: '#83b941' },
    { backgroundColor: '#16a4db' },
    { backgroundColor: '#ff843c' },
    { backgroundColor: '#77FF11' },
    { backgroundColor: '#00ffff' }
  ];

  chartOptions: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: ''
    }
  };

  chartLegend = true;
  chartPlugins = [pluginDataLabels];

  chartData: Array<ChartDataSets> = [];


  constructor(private readonly swalService: SwalService) { }

  ngOnInit(): void {
    this.setChartOptions();
  }

  /**
   * setChartOptions
   * Configura las configuraciones especiales para cada uno de los tipos de graficos
   */
  private setChartOptions(): void {
    this.chartOptions.title.text = this.data.description;
    switch (this.data.chartType) {
      case 'bar':
        const barChartOptions: ChartOptions = {
          scales: { xAxes: [{}], yAxes: [{}] },
          plugins: {
            plugins: {
              datalabels: {
                color: 'black',
                labels: {
                  title: {
                    font: {
                      weight: 'bold'
                    }
                  },
                  value: {
                    color: 'black'
                  }
                }
              }
            }
          }
        }
        this.assignOptionsToChart(barChartOptions);
        break;
      case 'pie':
        const pieChartOptions: ChartOptions = {
          plugins: {
            datalabels: {
              color: 'white',
              borderColor: 'black',
              borderRadius: 3
            }
          },
        };
        const defaultChartColors: Array<Color> = [{
          backgroundColor: ['green', 'blue', '#E6E606', 'orange', 'red'],
        }];
        this.chartColors = defaultChartColors;
        this.assignOptionsToChart(pieChartOptions);
        break;
    }
  }

  /**
   * assignOptionsToChart
   * Assigna las opciones al grafico
   * @param options 
   */
  private assignOptionsToChart(options: ChartOptions): void {
    Object.assign(this.chartOptions, options);
  }

  /**
   * showInfoGraphic
   * Muestra la descripción del grafico
   */
  showInfoGraphic(): void {
    this.swalService.info(this.data.description);
  }

}
