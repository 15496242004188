import * as tslib_1 from "tslib";
import { CalendarEventTitleFormatter } from 'angular-calendar';
import { formatDate } from '@angular/common';
var CustomEventTitleFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomEventTitleFormatter, _super);
    function CustomEventTitleFormatter(locale) {
        var _this = _super.call(this) || this;
        _this.locale = locale;
        return _this;
    }
    // you can override any of the methods defined in the parent class
    CustomEventTitleFormatter.prototype.month = function (event) {
        return this.getRangeTime(event.start, event.end) + " " + event.title;
    };
    CustomEventTitleFormatter.prototype.week = function (event) {
        return "\n    <br>\n    <span class=\"title\">\n    " + this.getRangeTime(event.start, event.end) + "\n    <br>\n    " + event.title + "\n    </span>";
    };
    CustomEventTitleFormatter.prototype.day = function (event) {
        return "\n    <br>\n    " + this.getRangeTime(event.start, event.end) + "  " + event.title;
    };
    CustomEventTitleFormatter.prototype.monthTooltip = function (event) {
        return;
    };
    /**
     * getRange time
     * Formatea el rango de horas de un evento
     * @param start Hora de inicio del evento
     * @param end Hora del fin del evento
     * @returns string
     */
    CustomEventTitleFormatter.prototype.getRangeTime = function (start, end) {
        return " <b>" + formatDate(start, 'hh:mm a', this.locale) + "-" + formatDate(end, 'hh:mm a', this.locale) + "</b>";
    };
    return CustomEventTitleFormatter;
}(CalendarEventTitleFormatter));
export { CustomEventTitleFormatter };
