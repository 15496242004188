import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CalendarService = /** @class */ (function () {
    function CalendarService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * getAssignedTickets
     * Obtiene la lista de los tickets con el estatus assignado
     * con el formato necesario para el calendario
     * @param params
     * @returns 200 | 404 | 500
     */
    CalendarService.prototype.getAssignedTickets = function (params) {
        return this.http.post(this.baseUrl + "/calendar/getAssignedTickets", params);
    };
    /**
     * updateOnlye date
     * Manda una petición put para actualizar la fecha de un evento
     * @param params
     * @returns 200 | 404 | 500
     */
    CalendarService.prototype.updateOnlyDate = function (params) {
        return this.http.put(this.baseUrl + "/calendar/updateOnlyDate", params);
    };
    CalendarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CalendarService_Factory() { return new CalendarService(i0.ɵɵinject(i1.HttpClient)); }, token: CalendarService, providedIn: "root" });
    return CalendarService;
}());
export { CalendarService };
