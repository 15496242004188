import { Component, OnDestroy, OnInit } from '@angular/core';
import { ServicesDashboardService } from '../../../../services/services-dashboard.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ticketsTypes } from '../../../../constants/tickets';
import { Subscription } from 'rxjs';
import { chartParams } from '../../../../model/shared-component.interface';

@Component({
  selector: 'app-tickets-closed-by-month',
  templateUrl: './tickets-closed-by-month.component.html',
  styleUrls: ['./tickets-closed-by-month.component.scss']
})
export class TicketsClosedByMonthComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  isLoad = false;
  data: chartParams = {
    title: 'Tickets cerrados por mes',
    description: 'Obtiene los tickets cerrados por mes en un periodo de 1 año',
    chartType: 'bar',
    chartLabels: [],
    chartData: []
  };
  fieldFrom: FormControl;
  fieldTo: FormControl;
  form: FormGroup = this.formBuilder.group({
    from: [],
    to: []
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly servicesDashboardService: ServicesDashboardService
  ) { }

  ngOnInit(): void {
    this.setForm();
    this.getTicketsClosedByMonth();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * getTicketsClosedByMonth
   * Obtiene y formatea la información de los tipos de tickets cerrados por mes
   * @param dateRangeParams 
   */
  getTicketsClosedByMonth(dateRangeParams?): void {
    this.subscriptions.push(
      this.servicesDashboardService.ticketsClosedByMonth(dateRangeParams).subscribe((resp: any) => {
        if (resp.success) {
          const dataSets = [];
          const responseData = resp.response;
          const axisXLabels = Object.keys(responseData);
          this.data.chartLabels = axisXLabels;
          for (const monthData in responseData) {
            for (const type in responseData[monthData]) {
              const formatedType = ticketsTypes[type];
              const dataSet = dataSets.find((data) => data.label === formatedType);
              const amount = responseData[monthData][type];
              if (dataSet) {
                dataSet.data.push(amount);
              } else {
                dataSets.push({ label: formatedType, data: [amount] });
              }
            }
          }
          this.data.chartData = dataSets;
        }
      })
    );
  }

  setForm(): void {
    this.fieldFrom = this.form.get('from') as FormControl;
    this.fieldTo = this.form.get('to') as FormControl;
  }

}
