import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarView, CalendarEvent, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { isSameMonth, isSameDay, } from 'date-fns';
import { Subject } from 'rxjs';
import { TicketsService } from '../../../services/tickets.service';
import { CalendarService } from '../../../services/calendar.service';
import { ticketsTableOptions } from '../../../constants/tickets';
import { BroadcastService } from '../../../services/broadcast.service';
import { environment } from '../../../../environments/environment';
import { ModalComponent } from '../../modal/modal.component';
import { SwalService } from '../../../services/swal.service';
import { SharedComponent } from '../../../model/shared-component';
import { ShowTicketInfoComponent } from '../../../admin/service/show-ticket-info/show-ticket-info.component';
var CalendarComponent = /** @class */ (function () {
    function CalendarComponent(modal, appModal, broadcast, cdRef, calendarService, swalService, ticketsService) {
        this.modal = modal;
        this.appModal = appModal;
        this.broadcast = broadcast;
        this.cdRef = cdRef;
        this.calendarService = calendarService;
        this.swalService = swalService;
        this.ticketsService = ticketsService;
        this.locale = 'es-MX';
        this.dayStartHour = 8;
        this.dayEndHour = 19;
        this.weekStartsOn = 1;
        this.ticketTableOptions = ticketsTableOptions;
        this.notSupportedEvents = environment.notSupportedEvents;
        this.subscriptions = [];
        this.view = CalendarView.Week;
        this.CalendarView = CalendarView;
        this.viewDate = new Date();
        this.refresh = new Subject();
        this.events = [];
        this.activeDayIsOpen = true;
    }
    CalendarComponent.prototype.ngOnInit = function () {
        this.getAssignedTickets();
    };
    CalendarComponent.prototype.ngAfterViewChecked = function () {
        this.cdRef.detectChanges();
    };
    CalendarComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    CalendarComponent.prototype.dayClicked = function (_a) {
        var date = _a.date, events = _a.events;
        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                events.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
            }
            this.viewDate = date;
        }
    };
    /**
     * eventTimesChanged
     * @param param
     */
    CalendarComponent.prototype.eventTimesChanged = function (_a) {
        var event = _a.event, newStart = _a.newStart, newEnd = _a.newEnd;
        this.events = this.events.map(function (iEvent) {
            if (iEvent === event) {
                return tslib_1.__assign({}, event, { start: newStart, end: newEnd });
            }
            return iEvent;
        });
        this.updateEventTime(event, newStart, newEnd);
    };
    /**
     * eventClicked
     * @param event
     */
    CalendarComponent.prototype.eventClicked = function (event) {
        var component = ShowTicketInfoComponent;
        var props = new SharedComponent(component, event.data, { title: "Informaci\u00F3n del evento " + event.title });
        this.appModal.opensm(props);
    };
    CalendarComponent.prototype.deleteEvent = function (eventToDelete) {
        this.events = this.events.filter(function (event) { return event !== eventToDelete; });
    };
    CalendarComponent.prototype.setView = function (view) {
        this.view = view;
    };
    CalendarComponent.prototype.closeOpenMonthViewDay = function () {
        this.activeDayIsOpen = false;
    };
    /**
     * navigateToDay
     * Renderiza la vista de dia del calendario cuando se selecciona un dia en
     * la vista del mes o el header de la vista de la semana
     * @param date fecha la cual se desea renderear en la vista CalendarView.Day
     */
    CalendarComponent.prototype.navigateToDay = function (date) {
        this.viewDate = date;
        this.setView(this.CalendarView.Day);
    };
    /**
     * handleEvent
     * Actualiza la fecha y hora del evento al cual se le haya efectuado un evento
     * resize o drag and drop
     * @param event evento a actualizar
     * @param newStart nuevo fecha de inicio
     * @param newEnd nueva fecha de fin
     */
    CalendarComponent.prototype.updateEventTime = function (event, newStart, newEnd) {
        var _this = this;
        var params = {
            type: 'calendar',
            details: event['details'],
            // id_assignations: event['id_assignations'],
            newStart: this.getStringDate(newStart),
            newEnd: this.getStringDate(newEnd)
        };
        if ((newStart !== event.start) || (newEnd !== event.end)) {
            this.subscriptions.push(
            // this.calendarService.updateOnlyDate(params).subscribe((resp) => {
            //   if (resp.success) {
            //     this.swalService.success();
            //   }
            // })
            this.ticketsService.update(event['id_tickets'], params).subscribe(function (resp) {
                if (resp.success) {
                    _this.swalService.success();
                }
            }));
        }
    };
    /**
     * getAssignedTickets
     * Obtiene los tickets con status asignado y añade las acciones para los eventos
     * asignar, desaasignar, marcar como no realizado
     */
    CalendarComponent.prototype.getAssignedTickets = function () {
        var _this = this;
        this.subscriptions.push(this.calendarService.getAssignedTickets().subscribe(function (response) {
            if (response.response) {
                _this.events = response.response;
                _this.events.forEach(function (ticketEvent) {
                    _this.setEventOptions(ticketEvent);
                });
            }
        }));
    };
    /**
     * setEventOptions
     * Configura las opciones de los eventos a mostrar
     * @param event de tipo ticket o general
     */
    CalendarComponent.prototype.setEventOptions = function (event) {
        event.start = new Date(event.start);
        event.end = new Date(event.end);
        event.draggable = true;
        event.resizable = {
            beforeStart: true,
            afterEnd: true,
        };
    };
    /**
     * getStringDate
     * Convierte el date devuelto por el calendario y lo transforma en un string
     * para enviar al server
     * @param date fecha a transformar
     * @returns string
     */
    CalendarComponent.prototype.getStringDate = function (date) {
        return date.toString().split('(')[0];
    };
    return CalendarComponent;
}());
export { CalendarComponent };
