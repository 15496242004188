import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ServicesDashboardService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }

    /**
     * closedTicketsPerDays
     * Manda una petición GET para obtener los porcentajes de los tickets cerrados
     * el mismo, 1, 3, 5 y mas de 5 dias, este calculo igual incluye a los tickets marcados como no realizados
     * @returns 200
     */
    closedTicketsPerDays(): Observable<any> {
        return this.http.get(`${this.baseUrl}/services_dashboard/closedTicketsPerDays`);
    }

    /**
     * ticketsClosedByMonth
     * Manda una petición GET para obtener los tickets cerrados por mes en un periodo establecido
     * la información devuelta es un array que agrupa los tipos por meses
     * @param params opcional array de rango de fechas
     * @returns 200
     */
    ticketsClosedByMonth(params?): Observable<any> {
        return this.http.get(`${this.baseUrl}/services_dashboard/ticketsClosedByMonth`, { params });
    }

    /**
     * totalDebtPerWeek
     * Manda una peticion GET para obtener los montos de la deuda a inicio, fin y total cobrado por semana
     * en un periodo de 2 meses
     * @returns 200
     */
    totalDebtPerWeek(): Observable<any> {
        return this.http.get(`${this.baseUrl}/services_dashboard/totalDebtPerWeek`);
    }
}