import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { ChargesService } from '../../../services/charges.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';
import { Subscription } from 'rxjs';
import { SharedComponent } from '../../../model/shared-component';
import { ChargeSummaryComponent } from '../charge-summary/charge-summary.component';
import { ModalComponent } from '../../modal/modal.component';
import { HelpersService } from '../../../services/helpers.service';
@Component({
  selector: 'app-manual-payment',
  templateUrl: './manual-payment.component.html',
  styleUrls: ['./manual-payment.component.scss']
})
export class ManualPaymentComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  // active_reference = false;
  // last_card_charge = false;
  show_months_ahead = true;
  showMonthlyFeeOnDebt = false;
  // active_reference_info: any;
  // last_card_charge_info: any;
  client: any;
  client_pending_debts: [];
  number_debts = [];
  current_month: Number;
  current_period_month: Number;
  payment_type: string;
  total_offline: Number;
  offline_form: FormGroup;
  credit_applied = 0;
  credit_remaining_amount = 0;
  months_ahead = environment.months_ahead;
  payment_types = environment.payment_types;
  payment_debt_options = environment.payment_debt_options;
  partial_payment = false;
  offline_format: any;
  totalMonthsAhead = 0;
  whatsAppMessage: string = '';

  form: FormGroup = this.formBuilder.group({
    months_ahead: ['', Validators.required],
    payment_types: ['', Validators.required],
    add_months_ahead: [''],
    id_charge_type: [''],
    id_clients: [''],
    used_credit: [false],
    total: [0]
  });

  constructor(
    public activeModal: NgbActiveModal,
    public modal: NgbModal,
    public readonly clientService: ClientsService,
    // private readonly appModal: ModalComponent,
    private readonly chargesService: ChargesService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly broadcast: BroadcastService,
    private readonly swal: SwalService,
    private readonly helpersService: HelpersService,
    private readonly cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getClient();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  /**
   * getClient
   * Obtiene la info del cliente
   * Valida si se puede crear un cargo para la mensualidad actual
   * Calcula el total por el cual se creara el cargo.
   */

  getClient(): void {
    this.subscriptions.push(this.clientService.show(this.data.client.id_clients).subscribe((data: any) => {
      this.client = data.response;
      this.client_pending_debts = this.client.pending_debts;
      this.validateCurrentPeriod(this.client);
      if (this.client.active_promotional_monthly_fee || this.data.firstCharge) {
        this.show_months_ahead = false;
        this.form.removeControl('months_ahead');
      }

      if (this.client.debt > 0) {
        this.show_months_ahead = false;
        if (this.form.get('months_ahead')) {
          this.form.removeControl('months_ahead');
        }

        if (this.client_pending_debts.length > 1) {
          this.form.addControl('payment_debt_option', this.formBuilder.control('', [Validators.required]));
        }
      }
      // this.checkCurrentPayments(this.client.id_clients);
    }));
  }

  /**
   * manualPayment
   * Envia a la API los parametros para generar un cargo OXXO/SPEI/Tarjeta ya sea para primer pago, pago de deuda, pago de meses adelantados
   */
  manualPayment(): void {
    this.form.controls.id_clients.setValue(this.client.id_clients);
    this.payment_type = this.form.get('payment_types').value;
    this.form.get('total').setValue(this.totalManualCharge());
    const endpoint = this.data.firstCharge ? 'createFirstCharge' : 'createManualCharge';
    if (this.form.valid) {
      this.subscriptions.push(this.chargesService[endpoint](this.form.value).subscribe((resp: any) => {
        if (resp.success) {
          this.showSuccessModal(this.payment_type, resp);
          this.activeModal.dismiss();
        } else {
          this.swal.error({ title: 'Ocurrio un error al momento de generar el pago' });
        }
      }));
    }
  }

  /**
   * totalManualCharge
   * Calcula el total a pagar al momento de generar un cargo OXXO|SPEI|Tarjeta
   * ya sea para un primer cargo o bien para pagar una deuda.
   *  + Si el pago generado es para un primer pago el total sera el recibido por data.contract.total
   *  + Si el pago no es primer pago verifica lo siguiente:
   *    - Si el cliente no tiene deudas el total sera la mensualidad + responsabilidad social (en caso de tener) + meses por adelantado.
   *    - Si el cliente tiene deudas estas se intentaran cobrar primero, las deudas pueden ser pagadas de la siguiente forma:
   *      * De manera total se cobrara toda la deuda que posea el cliente
   *      * De manera parcial se seleccionara y calculara la cantidad total a pagar con el numero total de deudas seleccionadas, el calculo
   *        se hara en base al array de deudas pendientes devueltas por la API
   * + Si el cliente no possee ningun adeudo el sistema permitira cobrar meses por adelantado.
   * + Si el cliente tiene una promoción por mensualidades no se permitira el cobro por meses por adelantado.
   */
  totalManualCharge(): Number {
    let total = 0;
    let chargeType = 3;
    const clientDebts = this.data.client.pending_debts;
    const idChargeType = this.form.controls.id_charge_type;
    const contract_total = parseFloat(this.data.contract.total);
    const sr = parseFloat(this.data.contract.sr);
    const monthly_fee = parseInt(this.data.contract.monthly_fee, 10) + sr;
    const activePromotional = this.client.active_promotional_monthly_fee;
    const months = this.form.get('months_ahead');
    this.credit_remaining_amount = this.creditRemainingAmount(this.client.active_credit);

    switch (this.data.firstCharge) {
      case false:
        total = monthly_fee;

        if (!activePromotional && months) {
          chargeType = months.value === '0' ? chargeType : 2;
          total = months.value === '0' ? monthly_fee : this.chargesService.calculateMonthsAheadAmount(monthly_fee, months.value);
        }

        if (clientDebts.length > 0) {
          chargeType = clientDebts.length === 1 ? 4 : 5;
          this.checkPaymentType();
          this.addMonthsAhead();

          total = (this.client.debt) / 100;

          if (this.partial_payment) {
            const number_debts_selected = this.form.get('number_debts_selected').value;
            const total_debts = this.calculateTotalSelectedDebts(number_debts_selected, this.client_pending_debts);
            total = total_debts;
          }

          if (months) {
            total += months.value === '0' ? monthly_fee : this.chargesService.calculateMonthsAheadAmount(monthly_fee, months.value);
          }

        }
        break;

      default:
        this.addMonthsAhead();
        const regularMonthlyFee = this.clientService.calculateMonthlyFee(this.client);
        this.totalMonthsAhead = 0;
        if (months) {
          this.totalMonthsAhead = this.chargesService.calculateMonthsAheadAmount(regularMonthlyFee, months.value);
        }
        total = contract_total + this.totalMonthsAhead;
        break;
    }

    idChargeType.setValue(chargeType);

    if (!this.data.firstCharge && this.credit_remaining_amount > 0) {
      const originalTotal = total;
      const totalWithCredit = total - this.credit_remaining_amount;
      if (totalWithCredit <= 0 || totalWithCredit >= 10) {
        total = totalWithCredit;
        this.credit_applied = originalTotal - totalWithCredit;
        if (this.credit_applied <= 1) {
          this.credit_applied = originalTotal;
        }

        this.form.controls.used_credit.setValue(true);
      }
    }

    total = total < 0 ? 0 : total;
    return Math.round(total * 100) / 100;
  }

  /**
   * calculateTotalSelectedDebts
   * @param number_debts_selected cantidad de meses de deuda a pagar 
   * @param client_debts deudas pendientes del cliente (id_charge null)
   * @returns total de la suma de las deudas seleccionadas
   */

  calculateTotalSelectedDebts(number_debts_selected: number, client_debts: Array<any>): number {
    const filtered_debts = client_debts.slice(0, number_debts_selected);
    const filtered_debts_ids = [];
    let total_debt_pay = 0;
    this.form.removeControl('selected_debts');

    filtered_debts.forEach(debt => {
      total_debt_pay += Number(debt.amount) + Number(debt.moratory_fees) + Number(debt.collection_fees);
      filtered_debts_ids.push(debt.id_debts);
    });

    this.form.addControl('selected_debts', this.formBuilder.control(filtered_debts_ids, [Validators.required]));

    return (total_debt_pay) / 100;
  }


  /**
   * showChargeInfo
   * Muestra la informacion del ultimo cargo por tarjeta o referencia en las ultimas 48 hrs del cliente.
   */
  // showChargeInfo(chargeType): void {
  //   const data = chargeType === 'card' ? this.last_card_charge_info : this.active_reference_info;
  //   const formatData = this.helpersService.flatObject(data);
  //   const props: SharedComponent = new SharedComponent(
  //     ChargeSummaryComponent,
  //     formatData,
  //     { title: `Resumen del cargo de ${this.data.client.name}` }
  //   );
  //   this.appModal.opensm(props);
  // }

  /**
   * cancelLastCharge
   * Manda una petición Put a la API para actualizar la columna extradata y setear el status canceled
   * para que las validaciones de la API permitan generar otra referencia
   */
  // cancelLastCharge(type: string): void {
  //   const message = type === 'reference' ? 'Referencia cancelada' : 'Bloqueo removido';
  //   const chargeInfo = type === 'reference' ? this.active_reference_info : this.last_card_charge_info;
  //   const referenceIdCharge = chargeInfo.response.charge_data.charge_data.id_charges;
  //   const params = { type };

  //   if (type === 'reference') {
  //     Object.assign(params, { id_charges: referenceIdCharge });
  //   }

  //   if (type === 'card') {
  //     Object.assign(params, { id_clients: this.client.id_clients });
  //   }

  //   this.subscriptions.push(this.chargesService.cancelLastCharge(params).subscribe((resp: any) => {
  //     if (resp.success) {
  //       this.swal.success({ title: `${message} exitosamente` }).then(() => {
  //         this.activeModal.dismiss();
  //         this.broadcast.reloadDataTable();
  //       });
  //     } else {
  //       this.swal.error({ title: 'Ocurrio un error al momento de cancelar la referencia' });
  //     }
  //   }));
  // }

  /**
   * creditRemainingAmount
   * Verifica si el cliente tiene credito valido para hacer un descuento
   * @param activeCredit objeto con la informacion de un credito valido.
   * @returns number con la funcion
   */
  creditRemainingAmount(activeCredit): number {
    let creditRemainingAmount = 0;
    if (activeCredit) {
      if (activeCredit.status && activeCredit.remaining_amount > 100) {
        creditRemainingAmount = activeCredit.remaining_amount;
      }
    }

    return creditRemainingAmount / 100;
  }

  sendByWhatsApp() {
    this.helpersService.sendByWhatsapp(this.whatsAppMessage, this.data.client.phone);
  }

  /**
   * validateCurrentPeriod
   * Verifica si el next_payday del cliente a un es valida para determinar si se puede crear o no 
   * una referencia para la mensualidad actual.
   * @param client info del cliente
   */

  private validateCurrentPeriod(client): void {
    this.current_month = new Date().getMonth() + 1;
    this.current_period_month = new Date(`${client.next_payday}T11:59:59`).getMonth() + 1;
    // tslint:disable-next-line: strict-comparisons
    if (this.current_period_month !== this.current_month) {
      this.months_ahead = this.months_ahead.slice(1);
    }
  }

  /**
   * checkPaymentType
   * Valida si la deuda del cliente se pagara de manera parcial o total
   * + Si se seleciona que el pago de la deuda sera parcial setea @number_debts y añade al form number_debts_selected
   */

  private checkPaymentType(): void {
    if (this.data.client.pending_debts.length > 1) {
      this.subscriptions.push(this.form.get('payment_debt_option').valueChanges.subscribe(payment_debt_option_value => {
        if (payment_debt_option_value === 'partial') {

          this.partial_payment = true;
          this.number_debts = new Array(this.client_pending_debts.length);
          this.form.addControl('number_debts_selected', this.formBuilder.control('', [Validators.required]));

          for (let i = 0; i < this.client_pending_debts.length; i++) {
            this.number_debts[i] = { id: i + 1, name: i + 1 };
          }

        } else {
          this.partial_payment = false;
          this.form.removeControl('number_debts_selected');
        }
      }));
    }
  }

  /**
   * addMothsAhead
   * Evalua si se desean añadir meses por adelantado al pago.
   */
  private addMonthsAhead(): void {
    this.subscriptions.push(this.form.get('add_months_ahead').valueChanges.subscribe(value => {
      const monthsAheadControl = this.form.get('moths_ahead');
      if (value === 1) {
        this.show_months_ahead = true;
        this.showMonthlyFeeOnDebt = true;
        if (!monthsAheadControl) {
          this.form.addControl('months_ahead', new FormControl('', Validators.required));
        }
      } else {
        this.show_months_ahead = false;
        this.showMonthlyFeeOnDebt = false;
        this.form.removeControl('months_ahead');
      }
    }));
  }

  /**
   * checkCurrentPayments
   * Verifica el estado de la ultima referencia (OXXO|SPEI) o 
   * de la tarjeta para verificar si se puede crear una referencia o cargo por tarjeta.
   */
  // private checkCurrentPayments(id_client): void {
  //   this.subscriptions.push(this.clientService.checkLastPayments(id_client).subscribe((last_payments_responses: any) => {
  //     last_payments_responses.forEach((charge_response, index) => {
  //       switch (index) {
  //         case 0:
  //           if (!charge_response.success) {
  //             this.active_reference = true;
  //             this.active_reference_info = charge_response;
  //           }
  //           break;
  //         case 1:
  //           if (!charge_response.success) {
  //             this.last_card_charge = true;
  //             this.last_card_charge_info = charge_response;
  //           }
  //           break;
  //       }
  //     });
  //   }));
  // }

  /**
   * showSuccessModal
   * Muestra el modal de exito cuando una referencia OXXO|SPEI o cargo por tarjeta es efectuado exitosamente.
   * @param paymentType tipo de cargo que se realizo Tarjeta|OXXO|SPEI
   * @param resp unicamente se usa si el cargo fue un OXXO|SPEI para mostra el modal de la información de la referencia.
   */
  private showSuccessModal(paymentType, resp?): void {
    if (paymentType === 'CARD') {
      this.swal.success({ title: 'Transación efectuada exitosamente' }).then(() => {
        this.activeModal.dismiss();
        this.broadcast.reloadDataTable();
      });
    } else {
      const whatsAppButton = `<button type="button" class="btn btn-success" id="sendByWa" title="Enviar por Whatsapp">
                                <i class="fa fa-whatsapp"></i>
                              </button>`;
      this.offline_format = this.chargesService.formatMessage(resp, paymentType);
      this.whatsAppMessage = this.offline_format.copyText;

      const data = {
        title: this.offline_format.title,
        html: this.offline_format.msg + whatsAppButton,
        onBeforeOpen: () => {
          const sendByWa = document.getElementById('sendByWa');
          sendByWa.addEventListener('click', () => {
            this.sendByWhatsApp();
          });
        }
      };
      this.swal.success(data).then(() => {
        this.activeModal.dismiss();
        this.broadcast.reloadDataTable();
      });
    }
  }
}