import * as JWT from 'jwt-decode';
import { PermissionsService } from './permissions.service';
import * as i0 from "@angular/core";
import * as i1 from "./permissions.service";
var SideBarService = /** @class */ (function () {
    function SideBarService(permissionsService) {
        this.permissionsService = permissionsService;
        this.sideBarItems = [
            {
                name: 'Dashboard',
                url: '/admin',
                icon: 'fa fa-th-large',
                permissions: ['show-main-dashboard', 'show-sales-dashboard', 'show-investor-dashboard', 'show-admin-dashboard'],
                children: [
                    { name: 'Dashboard Principal', url: '/admin/inicio', permissions: ['show-main-dashboard'] },
                    { name: 'Dashboard Ventas', url: '/admin/dashboard-ventas', permissions: ['show-sales-dashboard'], onlyForTeam: [1, 3] },
                    {
                        name: 'Dashboard Inversores',
                        url: '/admin/dashboard-inversores',
                        permissions: ['show-investor-dashboard'],
                        onlyForTeam: [1, 3]
                    },
                    { name: 'Dashboard Admin', url: '/admin/dashboard-admin', permissions: ['show-admin-dashboard'] },
                    { name: 'Dashboard Servicios', url: '/admin/dashboard-servicios' }
                ]
            },
            {
                name: 'Clientes',
                url: '/admin/clientes',
                icon: 'fa fa-users',
                permissions: ['list-clients']
            },
            {
                name: 'Calendario',
                url: '/admin/calendario',
                icon: 'fa fa-calendar',
                permissions: ['list-clients']
            },
            {
                name: 'Servicio',
                url: '/admin/servicio',
                icon: 'fa fa-ticket',
                class: 'servicio',
                permissions: ['list-error-codes', 'list-tickets'],
                children: [
                    { name: 'Tickets', url: '/admin/servicio/tickets', permissions: ['list-tickets'] },
                    { name: 'Mapa del seguimiento', url: '/admin/servicio/mapa_de_tickets', permissions: ['list-tickets'] },
                    { name: 'Códigos de error', url: '/admin/servicio/codigos_de_error', permissions: ['list-error-codes'] }
                ]
            },
            {
                name: 'Social',
                url: '/admin/social',
                icon: 'fa fa-twitter',
                permissions: ['list-notifications', 'list-posts', 'show-app-usage', 'list-emails'],
                children: [
                    { name: 'Notificaciones', url: '/admin/social/notificaciones', permissions: ['list-notifications'] },
                    { name: 'Galeria', url: '/admin/social/galeria', permissions: ['list-posts'] },
                    { name: 'Emails previews', url: '/admin/social/emails', permissions: ['list-emails'] },
                    { name: 'Encuestas', url: '/admin/social/encuestas', permissions: ['list-emails'] },
                    { name: 'App usage', url: '/admin/administracion/app-usage', permissions: ['show-app-usage'] },
                    { name: 'Leadsources', url: '/admin/social/leadsources', permissions: ['show-leadsources'] }
                ]
            },
            {
                name: 'Finanzas',
                url: '/admin/finanzas',
                icon: 'fa fa-usd',
                permissions: ['list-invoices', 'list-contracts', 'list-bad-debts'],
                children: [
                    { name: 'Contratos', url: '/admin/finanzas/contratos', permissions: ['list-contracts'] },
                    { name: 'Cargos recientes', url: '/admin/finanzas/cargos-recientes', permissions: ['list-charges'] },
                    { name: 'Responsabilidad Social', url: '/admin/finanzas/responsabilidad-social', permissions: ['list-contracts'] },
                    { name: 'Facturas', url: '/admin/finanzas/facturas', permissions: ['list-invoices'] },
                    { name: 'Malas deudas', url: '/admin/finanzas/malas-deudas', permissions: ['list-bad-debts'] },
                    { name: 'Contracargos', url: '/admin/finanzas/contracargos', permissions: ['list-charges'] }
                ]
            },
            {
                name: 'Vendedores',
                url: '/admin/vendedores',
                icon: 'fa fa-files-o',
                permissions: ['list-categories', 'list-groups', 'list-commissions'],
                children: [
                    // { name: 'Contratos', url: '/admin/vendedores/contratos' },
                    { name: 'Extras', url: '/admin/vendedores/extras', permissions: ['list-categories'] },
                    { name: 'Grupos', url: '/admin/vendedores/grupos', permissions: ['list-groups'], onlyForTeam: [1, 3] },
                    { name: 'Comisiones', url: '/admin/vendedores/comisiones', permissions: ['list-commissions'] },
                    { name: 'Promocionales - meses', url: '/admin/vendedores/promocionales-meses', permissions: ['list-promotional-monthly-fees'] }
                ]
            },
            {
                name: 'Bodega',
                url: '/admin/bodega',
                icon: 'fa fa-building',
                permissions: ['list-categories-supplies', 'list-supplies'],
                children: [
                    { name: 'Insumos', url: '/admin/bodega/insumos', permissions: ['list-supplies'], onlyForTeam: [1, 3] },
                    { name: 'Insumos categorias', url: '/admin/bodega/insumos-categorias', permissions: ['list-categories-supplies'], onlyForTeam: [1, 3] },
                ]
            },
            {
                name: 'Administración',
                url: '/admin/administracion',
                icon: 'fa fa-user',
                permissions: ['list-users', 'list-roles'],
                children: [
                    // { name: 'Administradores', url: '/admin/administracion/administradores' },
                    // { name: 'Técnicos', url: '/admin/administracion/tecnicos' },
                    { name: 'Usuarios', url: '/admin/administracion/usuarios', permissions: ['list-users'] },
                    { name: 'Roles', url: '/admin/administracion/roles', permissions: ['list-roles'] }
                ]
            },
            {
                name: 'IoT',
                url: '/admin/iot',
                icon: 'fa fa-sitemap',
                permissions: ['iot-clients', 'iot-readings', 'iot-average', 'iot-water-average'],
                onlyForTeam: [1, 3],
                children: [
                    { name: 'IoT devices', url: '/admin/iot/iot-devices' },
                    { name: 'Current readings', url: '/admin/iot/readings' },
                    { name: 'Hourly consumption', url: '/admin/iot/hourly-consumption' },
                    { name: 'Hourly consumption vs PPM', url: '/admin/iot/hourly-consumption-vs-ppm' },
                    { name: 'Historical', url: '/admin/iot/historical' }
                ]
            },
            {
                name: 'Dev Only',
                url: '/admin/dev',
                icon: 'fa fa-bug',
                permissions: ['show-dev-only'],
                children: [
                    { name: 'Asignación', url: '/dev/asignacion' },
                    { name: 'Encuestas', url: '/dev/encuestas' }
                ]
            }
        ];
    }
    Object.defineProperty(SideBarService.prototype, "SideBarItems", {
        get: function () {
            var _this = this;
            if (!Object.keys(this.permissionsService.getPermissions()).length) {
                var decode = JWT(localStorage.getItem('access_token'));
                this.permissionsService.setPermissions(decode.permissions);
            }
            var items = [];
            try {
                var itemsCopy = JSON.parse(JSON.stringify(this.sideBarItems));
                items = itemsCopy.filter(function (item) { return _this.filterOptions(item); });
            }
            catch (error) {
                //
            }
            return items;
        },
        enumerable: true,
        configurable: true
    });
    SideBarService.prototype.hasPermission = function (permissions) {
        var valid = false;
        for (var i = 0; i < permissions.length; i++) {
            if (this.permissionsService.hasPermission(permissions[i])) {
                valid = true;
                break;
            }
        }
        return valid;
    };
    // tslint:disable-next-line: prefer-function-over-method
    SideBarService.prototype.isDistributor = function (grantedTeams) {
        if (!grantedTeams) {
            return false;
        }
        var userTeam = JSON.parse(localStorage.getItem('user')).team_id;
        return !grantedTeams.includes(userTeam);
    };
    SideBarService.prototype.filterOptions = function (item) {
        var _this = this;
        var _item;
        if (item) {
            if (item.permissions) {
                if (this.hasPermission(item.permissions) && !this.isDistributor(item.onlyForTeam)) {
                    _item = item;
                }
            }
            else {
                _item = item;
            }
        }
        if (_item && item && item.children && item.children.length) {
            _item.children = item.children.filter(function (it) { return _this.filterOptions(it); }).slice();
        }
        return _item;
    };
    SideBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SideBarService_Factory() { return new SideBarService(i0.ɵɵinject(i1.PermissionsService)); }, token: SideBarService, providedIn: "root" });
    return SideBarService;
}());
export { SideBarService };
