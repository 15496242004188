import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { SalesDashboardService } from '../../../../services/sales-dashboard.service';
import { SwalService } from '../../../../services/swal.service';

@Component({
  selector: 'app-sales-agent-chart-custom',
  templateUrl: './sales-agent-chart-custom.component.html',
  styleUrls: ['./sales-agent-chart-custom.component.scss']
})
export class SalesAgentChartCustomComponent implements OnInit {

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  isLoad = true;
  barChartColors: Array<Color> = [
    { backgroundColor: '#ff843c' },
    { backgroundColor: '#83b941' },
    { backgroundColor: '#16a4db' }
  ];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true
          }
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true
          }
        }]
    },
    plugins: {
      plugins: {
        datalabels: {
          color: 'black',
          labels: {
            title: {
              font: {
                weight: 'bold'
              }
            },
            value: {
              color: 'black'
            }
          }
        }
      }
    }
  };
  barChartLabels: Array<Label> = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [pluginDataLabels];
  labelsValue: Array<any> = [];

  barChartData: Array<ChartDataSets> = [];
  fieldFrom: FormControl;
  fieldTo: FormControl;
  fieldAgent: FormControl;
  form: FormGroup = this.formBuilder.group({
    from: [],
    to: [],
    agent: ''
  });
  agents = [];
  leadTypes = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly swal: SwalService,
    private readonly salesDashboard: SalesDashboardService
  ) { }

  ngOnInit(): void {
    this.fieldFrom = this.form.get('from') as FormControl;
    this.fieldTo = this.form.get('to') as FormControl;
    this.fieldAgent = this.form.get('agent') as FormControl;
    this.setChart();
  }

  search(): void {
    const fromSub = this.form.get('from').value;
    const toSub = this.form.get('to').value;
    const fieldAgent = this.form.get('agent').value;
    const params = {
      from: fromSub,
      to: toSub,
      agent: fieldAgent
    };
    if (params.from && params.to) {
      this.setChart(params);
    } else {
      this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
    }
  }

  setChart(dateRangeParams?): void {
    this.isLoad = true;
    this.labelsValue = [];
    this.salesDashboard.owners().subscribe(res => {
      if (res.success) {
        if (this.agents.length === 0) {
          this.agents.push({ id: '', name: 'Todos' });
          for (const option of Object.values(res.response)) {
            this.agents.push({ id: option['id'], name: `${option['firstName']} ${option['lastName']}` });
          }
        }

        this.salesDashboard.salesByAgentCustom(dateRangeParams).subscribe((resp: any) => {
          // getting labels            
          if (resp.success) {
            const barChartLabelData = [];
            const keys = Object.keys(resp.response.data).sort();
            for (const index of keys) {
              if (index) {
                const ownerId = index.split('-')[1];
                for (const agent of this.agents) {
                  if (agent.id === ownerId) {
                    barChartLabelData.push(agent.name);
                  }
                }
              }
              // this.barChartLabels = barChartLabelData;
            }
            this.barChartLabels = [...barChartLabelData];
            const nameParam = {
              name: 'lead_promocion'
            };
            this.salesDashboard.contactProperty(nameParam).subscribe(response => {
              if (response.success) {
                const barChartDataL = [];
                const closeRateData = [];
                for (const option of response.response.data.options) {
                  const countByData = [];
                  for (const index of keys) {
                    const arrayByLabel = resp.response.data[index].filter(contact =>
                      contact.lead_promocion.indexOf(option.value) > -1);
                    countByData.push(arrayByLabel.length);
                    if (option.value === 'lead_ganado') {
                      closeRateData[index] = { ...closeRateData[index], lead_ganado: arrayByLabel.length };
                      // this.closeRateData[index].push({ lead_ganado: arrayByLabel.length });                  
                    }
                    if (option.value === 'lead_perdido') {
                      closeRateData[index] = { ...closeRateData[index], lead_perdido: arrayByLabel.length };
                    }
                  }
                  const data = { data: countByData, label: option.label, yAxisID: 'y-axis-1' };
                  barChartDataL.push(data);
                }

                const closeRateChart = [];
                for (const index of keys) {
                  if (closeRateData[index].lead_ganado > 0 && closeRateData[index].lead_perdido > 0) {
                    const closeRate = (closeRateData[index].lead_ganado /
                      // tslint:disable-next-line: restrict-plus-operands
                      (closeRateData[index].lead_ganado + closeRateData[index].lead_perdido)) * 100;
                    const valueFinal = +closeRate.toFixed(2);
                    closeRateChart.push(valueFinal);
                  } else {
                    closeRateChart.push(0);
                  }
                }
                // cuantos lead ganados por facebok ad lead, etc
                const closeRateDataChart = {
                  data: closeRateChart, label: 'Close Rate', type: 'line', datalabels: {
                    formatter: (value, context) =>
                      `${value}%`
                  },
                  yAxisID: 'y-axis-2'
                };
                barChartDataL.push(closeRateDataChart);
                // group by options            
                this.barChartData = [...barChartDataL];
                // this.barChartData = barChartDataL;
                this.isLoad = false;
              }
            });
          } else {
            this.swal.error({ title: 'Error recuperando los datos para la gráfica ventas por mes, intente de nuevo' });
          }
        });
      }
    });
  }

  showInfoGraphic(): void {
    const message = 'How many closed won, lost and valid based on leads generated that belongs an agent filtered by date range and agent. Close rate: closed won/closed lost';
    this.swal.info(message);
  }
}
