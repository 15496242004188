<div class="container-fluid bg-white p-3">
    <app-form-field type="select" [options]="{
        label: 'Selecciona como quieres crear el credito',
        path: 'credit_mode',
        droptext: 'Selecciona como quieres generar el credito',
        elements: creditModes
    }"></app-form-field>
    <div *ngIf="monthAmount">
        <app-form-field type="select" [options]="{
            label: 'Selecciona la cantidad de meses',
            path: 'months_ahead',
            droptext: 'Selecciona como quieres generar el credito',
            elements: months
        }"></app-form-field>
    </div>
    <div *ngIf="manualAmount || monthAmount ">
        <app-form-field type="input" [options]="{label: 'Cantidad', path: 'amount', disabled: disabled}">
        </app-form-field>
    </div>
    <app-form-field type="input" [options]="{label: 'Razón', path: 'extra_data.reason'}">
    </app-form-field>
    <div class="row" *ngIf="!hiddeApplyNowInput">
        <div class="col-md-8">
            <h6>¿Aplicar el credito de manera inmediata?</h6>
        </div>
        <div class="col-md-4">
            <app-form-field type="switch" [options]="{
                label: '', 
                path: 'applyNow',
                trueValue: 1, 
                falseValue: 0, 
                trueCaption: 'Si',
                falseCaption: 'No'}">
            </app-form-field>
        </div>
    </div>
    <p *ngIf="!hiddeApplyNowInput">Aplicar un credito de manera inmediata generara un cargo 0 por la cantidad de meses
        equivalente</p>
    <div class="row" *ngIf="!hiddeAutomaticInput">
        <div class="col-md-8">
            <h6>¿Desea que el daily charge aplique el credito de manera automatica?</h6>
        </div>
        <div class="col-md-4">
            <app-form-field type="switch" [options]="{
                label: '', 
                path: 'automatic', 
                trueValue: 1, 
                falseValue: 0, 
                trueCaption: 'Si',
                falseCaption: 'No'}">
            </app-form-field>
        </div>
    </div>
    <p *ngIf="!hiddeAutomaticInput">Al marcar esta opción como si se le indica al daily charge que haga uso del credito
        en la proxima fecha de pago</p>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="createCredit()">Crear
            credito</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>