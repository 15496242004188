<div class="modal-body">
    <div class="container">
        <span *ngIf="data; else notFound">
            <dl class="row">
                <dt class="col-sm-3">Descripción</dt>
                <dd class="col-sm-9">{{data?.description}}</dd>
                <dt class="col-sm-3">Técnico asignado:</dt>
                <dd class="col-sm-9">{{technician?.name}}</dd>
                <dt class="col-sm-3">Comentarios para el técnico:</dt>
                <dd class="col-sm-9">{{assignation?.details}}</dd>
            </dl>
            <app-tickets-actions [data]="data"></app-tickets-actions>
        </span>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
</div>


<ng-template #notFound>Datos del cliente no encontrados...</ng-template>