<div class="text-center" *ngIf="activeReference || lastCardCharge">
    <div *ngIf="lastCardCharge">
        <button type="button" class="btn btn-info" (click)="showChargeInfo('card')">Ultimo cargo con
            tarjeta</button>
        <button type="button" class="btn btn-danger" (click)="cancelLastCharge('card')">Remover bloqueo
            de tarjeta</button>
    </div>
    <br>
    <div class="bt-group" *ngIf="activeReference">
        <button type="button" class="btn btn-info" (click)="showChargeInfo('reference')">Referencia
            activa</button>
        <button type="button" class="btn btn-danger" (click)="cancelLastCharge('reference')">Cancelar
            referencia activa</button>
    </div>
    <br>
</div>