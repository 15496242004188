/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-dashboard.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tickets-closed-by-month/tickets-closed-by-month.component.ngfactory";
import * as i3 from "./tickets-closed-by-month/tickets-closed-by-month.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../services/services-dashboard.service";
import * as i6 from "./closed-tickets-per-day/closed-tickets-per-day.component.ngfactory";
import * as i7 from "./closed-tickets-per-day/closed-tickets-per-day.component";
import * as i8 from "./collected-debt-per-week/collected-debt-per-week.component.ngfactory";
import * as i9 from "./collected-debt-per-week/collected-debt-per-week.component";
import * as i10 from "../../../services/permissions.service";
import * as i11 from "./service-dashboard.component";
var styles_ServiceDashboardComponent = [i0.styles];
var RenderType_ServiceDashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServiceDashboardComponent, data: {} });
export { RenderType_ServiceDashboardComponent as RenderType_ServiceDashboardComponent };
export function View_ServiceDashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tickets-closed-by-month", [], null, null, null, i2.View_TicketsClosedByMonthComponent_0, i2.RenderType_TicketsClosedByMonthComponent)), i1.ɵdid(1, 245760, null, 0, i3.TicketsClosedByMonthComponent, [i4.FormBuilder, i5.ServicesDashboardService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-closed-tickets-per-day", [], null, null, null, i6.View_ClosedTicketsPerDayComponent_0, i6.RenderType_ClosedTicketsPerDayComponent)), i1.ɵdid(3, 245760, null, 0, i7.ClosedTicketsPerDayComponent, [i5.ServicesDashboardService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-collected-debt-per-week", [], null, null, null, i8.View_CollectedDebtPerWeekComponent_0, i8.RenderType_CollectedDebtPerWeekComponent)), i1.ɵdid(5, 245760, null, 0, i9.CollectedDebtPerWeekComponent, [i10.PermissionsService, i5.ServicesDashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_ServiceDashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-dashboard", [], null, null, null, View_ServiceDashboardComponent_0, RenderType_ServiceDashboardComponent)), i1.ɵdid(1, 114688, null, 0, i11.ServiceDashboardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ServiceDashboardComponentNgFactory = i1.ɵccf("app-service-dashboard", i11.ServiceDashboardComponent, View_ServiceDashboardComponent_Host_0, {}, {}, []);
export { ServiceDashboardComponentNgFactory as ServiceDashboardComponentNgFactory };
