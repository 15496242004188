/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./charts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts";
import * as i3 from "@angular/common";
import * as i4 from "./charts.component";
import * as i5 from "../../../services/swal.service";
var styles_ChartsComponent = [i0.styles];
var RenderType_ChartsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChartsComponent, data: {} });
export { RenderType_ChartsComponent as RenderType_ChartsComponent };
function View_ChartsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "chart"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i1.ɵdid(5, 999424, null, 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"], legend: [5, "legend"], plugins: [6, "plugins"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.chartData; var currVal_2 = _co.data.chartLabels; var currVal_3 = _co.chartOptions; var currVal_4 = _co.data.chartType; var currVal_5 = _co.chartColors; var currVal_6 = _co.chartLegend; var currVal_7 = _co.chartPlugins; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_ChartsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChartsComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.chartData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ChartsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-charts", [], null, null, null, View_ChartsComponent_0, RenderType_ChartsComponent)), i1.ɵdid(1, 114688, null, 0, i4.ChartsComponent, [i5.SwalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChartsComponentNgFactory = i1.ɵccf("app-charts", i4.ChartsComponent, View_ChartsComponent_Host_0, { data: "data" }, {}, []);
export { ChartsComponentNgFactory as ChartsComponentNgFactory };
