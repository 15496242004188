import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ContractComponent } from '../../admin/clients/contract/contract.component';
import { ModalComponent } from '../../components/modal/modal.component';
import { SharedComponent } from '../../model/shared-component';
import { BadDebtsService } from '../../services/bad-debts.service';
import { BroadcastService } from '../../services/broadcast.service';
import { ClientsService } from '../../services/clients.service';
import { PermissionsService } from '../../services/permissions.service';
import { SwalService } from '../../services/swal.service';
import { ClientChargesHistoryComponent } from './client-charges-history/client-charges-history.component';
import { ClientEditComponent } from './client-edit/client-edit.component';
import { ClientHistoryComponent } from './client-history/client-history.component';
import { ClientUpdatePlanComponent } from './client-update-plan/client-update-plan.component';
import { ManualPaymentComponent } from '../../components/shared/manual-payment/manual-payment.component';
import { CreateSupportTicketComponent } from './create-support-ticket/create-support-ticket.component';
import { ReferalsComponent } from './referals/referals.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { RoleGuardService } from '../../services/role-guard.service';
import { UsersService } from '../../services/users.service';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientCardsComponent } from './client-cards/client-cards.component';
import { environment } from '../../../environments/environment';
import { ClientCreditsComponent } from './client-credits/client-credits.component';
import { UpdateAcceptedClientsSalesGroupComponent } from './update-accepted-clients-sales-group/update-accepted-clients-sales-group.component';
import { ClientPromotionalsMonthlyFeesComponent } from './client-promotionals-monthly-fees/client-promotionals-monthly-fees.component';
import { ChargesService } from '../../services/charges.service';
import { AssociateChildAccountsComponent } from './associate-child-accounts/associate-child-accounts.component';
import { UniquePaymentComponent } from '../../components/shared/unique-payment/unique-payment.component';
import { clientsActionsOptions } from '../../constants/clients';
import { ClientExtraImagesComponent } from './client-extra-images/client-extra-images.component';
import { CreditsService } from '../../services/credits.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnDestroy, OnInit {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  notSupportedEvents = environment.notSupportedEvents;
  clientsOptions = clientsActionsOptions;
  sr = 0;
  dataTableConfig: any;
  logedUser: any;
  sales_manager_filter = false;
  cardsCondition: 'this.data.status === "accepted" || this.data.status === "standby"';
  contractData = {
    id_clients: 0,
    monthly_fee: 0,
    sr: 0,
    bank_fee: 0,
    coupon: 0,
    total: 0
  };

  private readonly baseDataTableConfig = {
    config: {
      base: this.clientsService,
      api: 'getClients',
      order: [[2, 'desc']]
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'client_data'
      },
      {
        display: 'Grupo de ventas',
        field: 'signed_in_group',
        type: 'client_sales_group',
        orderable: false
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'date',
        format: 'MMMM Do, YYYY, h:mm:ss a'
      },
      {
        display: 'Email',
        field: 'email',
        type: 'text',
        orderable: false
      },
      {
        display: 'Estatus',
        field: 'status',
        type: 'label',
        orderable: false,
        options: {
          emptyText: 'N/A'
        }
      },
      {
        display: 'Sig. Pago',
        field: 'next_payday',
        type: 'date'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: this.clientsOptions
      }
    ],
    filters: [
      {
        title: 'Estatus',
        // cssClass: 'col-lg-4 col-md-12 col-sm-12',
        cssClass: 'col',
        type: 'status',
        select_type: 'dropdown',
        options: [
          {
            val: '',
            name: 'Todos'
          },
          {
            val: 'standby',
            name: 'Pendiente'
          },
          {
            val: 'invalid',
            name: 'Invalido'
          },
          {
            val: 'canceled',
            name: 'Cancelado'
          },
          {
            val: 'rejected',
            name: 'Rechazado'
          },
          {
            val: 'accepted',
            name: 'Aceptado'
          }
        ]
      },
      {
        title: 'AguaGratis',
        // cssClass: 'col-lg-1 col-md-3 col-sm-3',
        cssClass: 'col',
        type: 'free_water',
        select_type: 'dropdown',
        options: [
          {
            val: 0,
            name: 'Todos'
          },
          {
            val: 1,
            name: 'Si'
          }
        ]
      },
      {
        title: 'Instalación',
        // cssClass: 'col-lg-3-5 col-md-6',
        cssClass: 'col-lg-3-5 col-md-6',
        type: 'serial_number',
        select_type: 'dropdown',
        options: [
          {
            val: '',
            name: 'Todos'
          },
          {
            val: 'instaled',
            name: 'Instalado'
          },
          {
            val: '',
            name: 'No instalado'
          }
        ]
      },
      {
        title: 'Metodo de pago',
        // cssClass: 'col-lg-2 col-md-6',
        cssClass: 'col',
        type: 'preferred_payment_method',
        select_type: 'dropdown',
        options: [
          {
            val: '',
            name: 'Todos'
          },
          {
            val: 1,
            name: 'Tarjeta'
          },
          {
            val: 2,
            name: 'OXXO'
          },
          {
            val: 3,
            name: 'SPEI'
          }
        ]
      },
      {
        title: 'Multicuentas',
        // cssClass: 'col-lg-2 col-md-6',
        cssClass: 'col',
        type: 'clientsWithSubAccounts',
        select_type: 'dropdown',
        options: [
          {
            val: '',
            name: 'Todos'
          },
          {
            val: '1',
            name: 'Si'
          }
        ]
      },
      {
        title: 'Agente de ventas',
        // cssClass: 'col-lg-2 col-md-6',
        cssClass: 'col',
        type: 'sales_agent',
        select_type: 'dropdown',
        options: [
          {
            val: '',
            name: 'Todos'
          },
          {
            val: '1',
            name: 'Activo'
          },
          {
            val: '0',
            name: 'Inactivo'
          }
        ]
      },
      {
        title: 'Fecha de cumpleaños',
        // cssClass: 'col-lg-2 col-md-6',
        cssClass: 'col',
        type: 'birth_date',
        select_type: 'dropdown',
        options: [
          {
            val: '',
            name: 'Todos'
          },
          {
            val: '1',
            name: 'Capturada'
          },
          {
            val: '0',
            name: 'No capturada'
          }
        ]
      },
    ]
  };

  constructor(
    private readonly clientsService: ClientsService,
    private readonly chargesService: ChargesService,
    private readonly creditsService: CreditsService,
    private readonly badDebts: BadDebtsService,
    private readonly broadcast: BroadcastService,
    private readonly roleGuardService: RoleGuardService,
    private readonly permissionService: PermissionsService,
    private readonly usersService: UsersService,
    public appModal: ModalComponent,
    public swal: SwalService
  ) { }

  ngOnInit(): void {
    this.checkView();
    this.configEvents();
    this.showTeams();
    this.filterClients();
    this.dataTableConfig = this.permissionService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * createClient
   * Abre un modal que permite registrar los datos de un cliente
   */
  createClient(): void {
    const props: SharedComponent = new SharedComponent(
      ClientCreateComponent,
      null,
      {
        title: 'Registrar cliente'
      }
    );
    this.appModal.open(props);
  }

  createSupportTicketItem(data): void {
    const props: SharedComponent = new SharedComponent(CreateSupportTicketComponent, data, { title: `Crear ticket de soporte para el cliente ${data.name}` });
    this.appModal.open(props);
  }

  contractItem(data): void {
    const logedUserRoles = this.usersService.getLogedUserRoles(this.logedUser.roles, ['Sales Agent', 'Sales Manager']);
    if (logedUserRoles.length > 0) {
      data.loggedUserRoles = logedUserRoles;
    }
    const props: SharedComponent = new SharedComponent(ContractComponent, data, { title: `Contrato de compra del cliente ${data.name}` });
    this.appModal.openXl(props);
  }

  clientEditItem(data): void {
    const props: SharedComponent = new SharedComponent(ClientEditComponent, data, { title: 'Edición del cliente' });
    this.appModal.openXl(props);
  }

  clientDeleteItem(data): void {
    this.swal.warning({ title: '¿Estás seguro de eliminar al cliente?' }).then(result => {
      if (result.value) {
        this.subscriptions.push(this.clientsService.delete(data.id_clients).subscribe((response: any) => {
          if (response.success) {
            this.swal.success().then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ text: response.message });
          }
        }));
      }
    });
  }

  clientChargeHistoryItem(data): void {
    const props: SharedComponent = new SharedComponent(ClientChargesHistoryComponent, data, { title: `Historial de cargos del cliente ${data.name}` });
    this.appModal.openXl(props);
  }

  clientHistoryItem(data): void {
    const props: SharedComponent = new SharedComponent(ClientHistoryComponent, data, { title: `Historial del cliente ${data.name}` });
    this.appModal.openXl(props);
  }

  /**
   * manualPaymentItem
   * Muestra el modal para crear un cargo de manera manual (deuda|meses adelantados)
   * @param client_data información del cliente.
   */
  manualPaymentItem(client_data): void {
    this.showManualPaymentModal(client_data);
  }

  referalsItem(data): void {
    const props: SharedComponent = new SharedComponent(ReferalsComponent, data, { title: `Contratos referidos del cliente ${data.name}` });
    this.appModal.openXl(props);
  }

  setAcceptedItem(data): void {
    const params = { status: 'accepted' };

    this.swal.warning({ title: '¿Estás seguro de aceptar al cliente?' }).then(result => {
      if (result.value) {
        this.clientsService.setStatus(data.id_clients, params).toPromise();
        this.swal.success().then(() => {
          this.broadcast.reloadDataTable();
        });
      }
    });
  }

  setBadStatusItem(data, event): void {
    let title_status: string;
    let status: string;

    switch (event) {
      case 'client.setCancelled':
        title_status = 'cancelado';
        status = 'canceled';
        break;

      case 'client.setInvalid':
        title_status = 'invalidado';
        status = 'invalid';
        break;

      case 'client.setRejected':
        title_status = 'rechazado';
        status = 'rejected';
        break;
    }

    title_status = `Favor de proporcionar las razones por las que el cliente será ${title_status}`;
    const swalParams = {
      title: 'Motivos',
      // text: title_status,
      inputPlaceholder: 'Razones'
    };

    if (data.not_closed_tickets.length > 0 && status === 'canceled') {
      const not_closed_tickets_ids = data.not_closed_tickets.map(key => key.id_tickets);
      const not_closed_tickets_text = data.not_closed_tickets.length === 1 ? 'ticket no cerrado' : 'tickets no cerrados';
      title_status += '<br/>';
      title_status += `<p style="color:red"> Advertencia este cliente cuenta con ${data.not_closed_tickets.length} ${not_closed_tickets_text} : ${not_closed_tickets_ids} </p>`;
      // tslint:disable-next-line: no-string-literal
      swalParams['html'] = title_status;
    } else {
      // tslint:disable-next-line: no-string-literal
      swalParams['text'] = title_status;
    }

    this.swal.input(swalParams).then((resp: any) => {
      if (resp.value) {
        this.subscriptions.push(this.clientsService.setStatus(data.id_clients, { status, reasons: resp.value }).subscribe((response: any) => {
          if (response.success) {
            this.swal.success().then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ text: response.message });
          }
        }));
      }
    });

  }

  moveToBadDebtItem(data): void {

    const swalParams = {
      title: 'Mover a mala deuda',
      text: 'Favor de proporcionar las razones por las que el cliente sera movido a mala deuda',
      inputPlaceholder: 'Razones'
    };

    this.swal.input(swalParams).then((resp: any) => {
      if (resp.value) {
        this.subscriptions.push(this.badDebts.moveToBadDebt(data.id_clients, { reason: resp.value }).subscribe((response: any) => {
          if (response.success) {
            this.swal.success().then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error();
          }
        }));
      }
    });
  }

  makeSalesAgentItem(data): void {
    const params = { role: 'Sales Agent' };
    const swal_title = `Estás seguro de cambiar el nivel del cliente ${data.name}`;

    this.swal.warning({ title: swal_title }).then(result => {
      if (result.value) {
        this.subscriptions.push(this.clientsService.makeSalesAgent(data.id_clients, params).subscribe((response: any) => {
          if (response.success) {
            this.swal.success().then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: response.message });
          }
        }));
      }
    });
  }

  /**
   * restorePasswordItem
   * Abre un modal para restablecer la contraseña de accesso de un cliente para la client app.
   * @param data información del cliente
   */
  restorePasswordItem(data): void {
    const props: SharedComponent = new SharedComponent(RestorePasswordComponent, data, { title: `Restaurar contraseña del cliente ${data.name}` });
    this.appModal.opensm(props);
  }

  /**
   * updatePlan
   * Abre un modal para poder actualizar el plan de un cliente de standard a premium o viceversa.
   * @param data información del cliente
   */
  updatePlan(data): void {
    const props: SharedComponent = new SharedComponent(ClientUpdatePlanComponent, data, { title: `Actualizar el plan del cliente ${data.name}` });
    this.appModal.opensm(props);
  }

  /**
   * updateMonthlyFee
   * Actualiza la mensualidad del cliente.
   * @param data información del cliente
   */
  updateMonthlyFee(data): void {
    let new_monthly_fee = 349;
    if (data.social_responsability) {
      const sr = Math.round(((new_monthly_fee / 1.16) * 0.007) * 100) / 100;
      new_monthly_fee = new_monthly_fee + sr;
    }

    const swalParams = {
      title: `¿Actualizar mensualidad del cliente a ${new_monthly_fee}?`,
      text: 'La mensualidad del cliente se actualizara, favor de proporcionar la razón',
      inputPlaceholder: 'Razones',
      confirmButtonText: 'Actualizar'
    };

    this.swal.input(swalParams).then((resp: any) => {
      if (resp.value) {
        this.subscriptions.push(this.clientsService.updateMonthlyFee(data.id_clients, { reason: resp.value }).subscribe((response: any) => {
          if (response.success) {
            this.swal.success({ text: 'Mensualidad actualizada exitosamente' }).then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error();
          }
        }));
      }
    });
  }

  /**
   * registeredCards
   * Abre un modal que permite visualizar las tarjetas registradas asociadas a un cliente
   * @param data información del cliente seleccionado
   */
  clientCards(data): void {
    const props: SharedComponent = new SharedComponent(ClientCardsComponent, data, { title: `Tarjetas del cliente ${data.name}` });
    this.appModal.opensm(props);
  }

  /**
   * changeDeviceRecoveryStatus
   * Manda una petición POST para determinar si un cliente cancelado devolvio o no un dispositivo,
   * envia un true si el dispositivo no fue recuperado en caso contrario envia un false
   * @param data informacion con el id del cliente
   */
  changeDeviceRecoveryStatus(data): void {
    const status = data.lost_device ? false : true;
    const status_text = data.lost_device ? 'recuperado' : 'perdido';
    const params = { status };
    const swal_title = `Estás seguro de querer marcar el dispositivo como ${status_text}`;

    this.swal.warning({ title: swal_title }).then(result => {
      if (result.value) {
        this.subscriptions.push(this.clientsService.changeDeviceRecoveryStatus(data.id_clients, params).subscribe((response: any) => {
          if (response.success) {
            this.swal.success().then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: response.message });
          }
        }));
      }
    });
  }

  clientCredit(data): void {
    const props: SharedComponent = new SharedComponent(ClientCreditsComponent, data, { title: `Credito otorgado al cliente ${data.name}` });
    this.appModal.open(props);
  }

  /**
   * clientPromotionalMonthlyFee
   * Abre un modal para ver el historial de promociones mensuales dadas a un cliente.
   * @param data 
   */
  clientPromotionalMonthlyFee(data): void {
    const props: SharedComponent = new SharedComponent(
      ClientPromotionalsMonthlyFeesComponent,
      data,
      { title: `Meses promocionales otorgado al cliente ${data.name}` }
    );
    this.appModal.open(props);
  }

  /**
   * freeMonthlyFee
   * Abre un modal con un input para dar 1 mes gratis a un cliente
   */
  freeMonthlyFee(data): void {
    const text = `Favor de proporcionar las razones por las que se dara la mensualidad gratis`;
    const swalParams = { title: 'Motivos', inputPlaceholder: 'Razones' };
    swalParams['text'] = text;

    this.swal.input(swalParams).then((resp: any) => {
      if (resp.value) {
        const params = {
          id_clients: data.id_clients,
          extra_data: { reason: resp.value },
          payment_types: 'OXXO',
          id_charge_type: 3,
          used_credit: true,
          oneMonthlyFeeFree: true,
          applyNow: true,
          automatic: false
        };

        this.subscriptions.push(this.creditsService.createCredit(params).subscribe((response: any) => {
          if (response.success) {
            this.swal.success().then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ text: response.message });
          }
        }));
      }
    });
  }

  /**
   * updateAcceptedClientsSalesGroup
   * Actualiza el grupo de venta de los clientes acceptados y pendientes
   */
  updateAcceptedClientsSalesGroup(): void {
    const props: SharedComponent = new SharedComponent(UpdateAcceptedClientsSalesGroupComponent, null, { title: 'Actualizar grupo de ventas de clientes' });
    this.appModal.opensm(props);
  }

  /**
   * associateChildAccounts
   * Abre un modal para ingresar las que pasaran a ser cuentas hijas de la cuenta padre pasada en data.
   * @param data información del cliente padre
   */
  associateChildAccounts(data): void {
    const props: SharedComponent = new SharedComponent(AssociateChildAccountsComponent, data, { title: `Asociar cuentas hijas al cliente ${data.name}` });
    this.appModal.opensm(props);
  }

  /**
   * disAssociateChildAccounts
   * Separa la cuenta hija de la cuenta padre
   * @param data información de la cuenta hija
   */
  disAssociateChildAccounts(data): void {
    const title = `¿Estás seguro de querer volver esta cuenta indivudual?`;
    const text = 'El cliente pasara a ser una cuenta individual por lo que se tendran que añadir datos de tarjeta y de facturación nuevamente';
    this.swal.warning({ title, text }).then(result => {
      if (result) {
        if (result.value) {
          this.subscriptions.push(this.clientsService.disAssociateChildAccounts(data.id_clients).subscribe((response: any) => {
            if (response.success) {
              this.swal.success().then(() => {
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: response.message });
            }
          }));
        }
      }
    });
  }

  /**
   * showAccounts
   * muesta la información de todas las cuentas asociadas a una cuenta padre
   * @param data información de Client de la cuenta padre.
   */
  showAccounts(data): void {
    const props: SharedComponent = new SharedComponent(
      ClientsComponent, data,
      { title: `Cuentas del cliente ${data.name}` });
    this.appModal.openXl(props);
  }

  /**
  * showUniquePaymentmodal
  * Abre un modal para configurar el pago de las multiples cuentas asociadas a un cliente.
  */
  showUniquePaymentModal(): void {
    const props: SharedComponent = new SharedComponent(UniquePaymentComponent, this.data,
      { title: 'Crear cargo único' });
    this.appModal.opensm(props);
  }

  /**
   * extraImages
   * Abre un modal para poder visualizar imagenes de la primera instalación regular/premium
   * y poder añadir hasta 5 imagenes extras al cliente.
   * @param data  información del cliente
   */
  extraImages(data): void {
    const props: SharedComponent = new SharedComponent(
      ClientExtraImagesComponent, data,
      { title: `Imagenes extra del cliente ${data.name}` });
    this.appModal.open(props);
  }

  /**
   * total
   * Obtiene el total de la mensualidad del cliente dado.
   * @param client información basica del cliente
   * @returns number devuelve el total de la mensualidad del cliente con o sin responsabilidad social.
   */
  total(client): number {

    if (client.social_responsability === 1) {
      this.contractData.sr = client.monthly_fee / 1.16 * 0.007;
    }
    const total = (client.monthly_fee / 100) + this.sr + 100;

    return total;
  }

  /**
   * filterClients
   * Filtra a los clientes si el usuario logueado en el sistema es un agente de ventas,
   * para unicamente mostrar a los clientes que este a conseguido.
   */
  filterClients(): void {
    const user = this.roleGuardService.decodedToken.user;
    if (user) {
      const roles = user.roles;
      if (roles.find(role => role.name === 'Sales Agent')) {
        Object.assign(this.baseDataTableConfig.config, { params: { id_users: user.id } });
        // this.baseDataTableConfig.config.params.id_users = user.id;
      }

      if (roles.find(role => role.name === 'Sales Manager')) {
        const smc_filter = {
          title: 'Referidos',
          cssClass: 'col',
          type: 'id_users',
          select_type: 'dropdown',
          options: [
            {
              val: '',
              name: 'Todos'
            },
            {
              val: user.id,
              name: 'Referidos'
            }
          ]
        };
        this.baseDataTableConfig.filters.push(smc_filter);
      }
    }
  }

  /**
   * configEvents
   * Configura los eventos de las opciones del cliente
   */
  private configEvents(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        const badStatus = ['client.setInvalid', 'client.setRejected', 'client.setCancelled'];
        if (badStatus.includes(event.name)) {
          this.setBadStatusItem(event.data, event.name);
        } else {
          if (this[event.name]) {
            this[event.name](event.data);
          }
        }
      }
    }));
  }

  /**
   * showManualPaymentModal
   * Abre un modal con las opciones para generar un pago via OXXO/SPEI
   * @param data información del cliente para general la referencia del pago
   */

  private showManualPaymentModal(client_data: any): void {
    if (client_data.completed_installation) {
      this.contractData.monthly_fee = client_data.monthly_fee / 100;
      this.contractData.total = this.total(client_data);
      if (client_data.social_responsability === 1) {
        this.contractData.sr = this.contractData.monthly_fee / 1.16 * 0.007;
      }
      const props: SharedComponent = new SharedComponent(
        ManualPaymentComponent,
        {
          client: client_data,
          contract: this.contractData,
          firstCharge: false
        },
        { title: 'Crear cargo' });
      this.appModal.openXl(props);
    } else {
      const swal_params = {
        title: 'No se puede generar el cargo',
        text: 'Favor de solicitar al tecnico cerrar el ticket de instalación',
        confirmButtonText: 'Aceptar',
        showCancelButton: false
      };
      this.swal.warning(swal_params);
    }
  }

  /**
   * showTeams
   * Determina si la columna de distribuidor se mostrara en el la tabla de clientes
   */
  private showTeams(): void {
    this.logedUser = this.roleGuardService.decodedToken.user;
    if (this.permissionService.hasPermission('list-teams') && this.logedUser && this.logedUser.team_id === 1) {
      const team_column = { display: 'Distribuidor', field: 'user.team.name', type: 'text' };
      const columns_length = this.baseDataTableConfig.columns.length;
      this.baseDataTableConfig.columns.splice(columns_length - 1, 0, team_column);
    }
  }

  /**
   * checkView
   * Revisa si la vista a abrir es para mostrar unicamente las cuentas de una cuenta padre o
   * si es para mostrar todos los clientes en general.
   */
  private checkView() {
    if (this.data && this.data.is_parent) {
      Object.assign(this.baseDataTableConfig.config, { params: { showAccounts: true, id_clients: this.data.id_clients } });
      this.baseDataTableConfig.filters = [];
      this.baseDataTableConfig.config.order = [[2, 'asc']];
      const actionsOptions = this.baseDataTableConfig.columns.find(column => column.type === 'actions').options;
      for (const option in actionsOptions) {
        if (actionsOptions[option].hasOwnProperty('showOnlyForParent')) {
          delete actionsOptions[option];
        }
      }
    }
  }
}
