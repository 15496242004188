import { Component, OnDestroy, OnInit } from '@angular/core';
import { ServicesDashboardService } from '../../../../services/services-dashboard.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { PermissionsService } from '../../../../services/permissions.service';

@Component({
  selector: 'app-collected-debt-per-week',
  templateUrl: './collected-debt-per-week.component.html',
  styleUrls: ['./collected-debt-per-week.component.scss']
})
export class CollectedDebtPerWeekComponent implements OnInit, OnDestroy {
  notSupportedEvents = environment.notSupportedEvents;
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];
  private readonly baseDataTableConfig = {
    config: {
      base: this.servicesDashboardService,
      api: 'totalDebtPerWeek'
    },
    columns: [
      {
        display: 'Total a inicio de semana',
        field: 'total_start_of_week',
        type: 'currency'
      },
      {
        display: 'Total a final de semana',
        field: 'total_end_of_week',
        type: 'currency'
      },
      {
        display: 'Total a cobrado',
        field: 'total_collected',
        type: 'currency'
      },
      {
        display: 'Porcentage de inc/dec',
        field: 'percentage',
        type: 'debt_collected'
      },
      {
        display: 'Rango de fechas',
        field: 'rangeDate',
        type: 'text'
      }
    ],
    filters: []
  }
  constructor(
    private readonly permissionsService: PermissionsService,
    private readonly servicesDashboardService: ServicesDashboardService
  ) { }

  ngOnInit(): void {
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }
  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

}
