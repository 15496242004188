import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ServicesDashboardService = /** @class */ (function () {
    function ServicesDashboardService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * closedTicketsPerDays
     * Manda una petición GET para obtener los porcentajes de los tickets cerrados
     * el mismo, 1, 3, 5 y mas de 5 dias, este calculo igual incluye a los tickets marcados como no realizados
     * @returns 200
     */
    ServicesDashboardService.prototype.closedTicketsPerDays = function () {
        return this.http.get(this.baseUrl + "/services_dashboard/closedTicketsPerDays");
    };
    /**
     * ticketsClosedByMonth
     * Manda una petición GET para obtener los tickets cerrados por mes en un periodo establecido
     * la información devuelta es un array que agrupa los tipos por meses
     * @param params opcional array de rango de fechas
     * @returns 200
     */
    ServicesDashboardService.prototype.ticketsClosedByMonth = function (params) {
        return this.http.get(this.baseUrl + "/services_dashboard/ticketsClosedByMonth", { params: params });
    };
    /**
     * totalDebtPerWeek
     * Manda una peticion GET para obtener los montos de la deuda a inicio, fin y total cobrado por semana
     * en un periodo de 2 meses
     * @returns 200
     */
    ServicesDashboardService.prototype.totalDebtPerWeek = function () {
        return this.http.get(this.baseUrl + "/services_dashboard/totalDebtPerWeek");
    };
    ServicesDashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServicesDashboardService_Factory() { return new ServicesDashboardService(i0.ɵɵinject(i1.HttpClient)); }, token: ServicesDashboardService, providedIn: "root" });
    return ServicesDashboardService;
}());
export { ServicesDashboardService };
