/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-ticket-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../tickets-actions/tickets-actions.component.ngfactory";
import * as i3 from "../tickets-actions/tickets-actions.component";
import * as i4 from "../../../components/modal/modal.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../services/tickets.service";
import * as i7 from "../../../services/broadcast.service";
import * as i8 from "../../../services/swal.service";
import * as i9 from "@angular/common";
import * as i10 from "./show-ticket-info.component";
var styles_ShowTicketInfoComponent = [i0.styles];
var RenderType_ShowTicketInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowTicketInfoComponent, data: {} });
export { RenderType_ShowTicketInfoComponent as RenderType_ShowTicketInfoComponent };
function View_ShowTicketInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "dl", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "dt", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Descripci\u00F3n"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "dd", [["class", "col-sm-9"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "dt", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["T\u00E9cnico asignado:"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "dd", [["class", "col-sm-9"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "dt", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comentarios para el t\u00E9cnico:"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "dd", [["class", "col-sm-9"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-tickets-actions", [], null, null, null, i2.View_TicketsActionsComponent_0, i2.RenderType_TicketsActionsComponent)), i1.ɵdid(15, 114688, null, 0, i3.TicketsActionsComponent, [i4.ModalComponent, i5.NgbActiveModal, i6.TicketsService, i7.BroadcastService, i8.SwalService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.data; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data == null) ? null : _co.data.description); _ck(_v, 5, 0, currVal_0); var currVal_1 = ((_co.technician == null) ? null : _co.technician.name); _ck(_v, 9, 0, currVal_1); var currVal_2 = ((_co.assignation == null) ? null : _co.assignation.details); _ck(_v, 13, 0, currVal_2); }); }
function View_ShowTicketInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["Datos del cliente no encontrados..."]))], null, null); }
export function View_ShowTicketInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowTicketInfoComponent_1)), i1.ɵdid(3, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cerrar"])), (_l()(), i1.ɵand(0, [["notFound", 2]], null, 0, null, View_ShowTicketInfoComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = i1.ɵnov(_v, 7); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ShowTicketInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-show-ticket-info", [], null, null, null, View_ShowTicketInfoComponent_0, RenderType_ShowTicketInfoComponent)), i1.ɵdid(1, 114688, null, 0, i10.ShowTicketInfoComponent, [i5.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShowTicketInfoComponentNgFactory = i1.ɵccf("app-show-ticket-info", i10.ShowTicketInfoComponent, View_ShowTicketInfoComponent_Host_0, { data: "data" }, {}, []);
export { ShowTicketInfoComponentNgFactory as ShowTicketInfoComponentNgFactory };
