import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { formatDate } from '@angular/common';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  // you can override any of the methods defined in the parent class

  month(event: CalendarEvent): string {
    return `${this.getRangeTime(event.start, event.end)} ${event.title}`;
  }

  week(event: CalendarEvent): string {
    return `
    <br>
    <span class="title">
    ${this.getRangeTime(event.start, event.end)}
    <br>
    ${event.title}
    </span>`;
  }

  day(event: CalendarEvent): string {
    return `
    <br>
    ${this.getRangeTime(event.start, event.end)}  ${event.title}`;
  }

  monthTooltip(event: CalendarEvent): string {
    return;
  }

  /**
   * getRange time
   * Formatea el rango de horas de un evento
   * @param start Hora de inicio del evento
   * @param end Hora del fin del evento
   * @returns string
   */
  private getRangeTime(start: Date, end: Date): string {
    return ` <b>${formatDate(start, 'hh:mm a', this.locale)}-${formatDate(end, 'hh:mm a', this.locale)}</b>`;
  }
}