/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./collected-debt-per-week.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/datatable/datatable.component.ngfactory";
import * as i3 from "../../../../components/datatable/datatable.component";
import * as i4 from "../../../../services/broadcast.service";
import * as i5 from "./collected-debt-per-week.component";
import * as i6 from "../../../../services/permissions.service";
import * as i7 from "../../../../services/services-dashboard.service";
var styles_CollectedDebtPerWeekComponent = [i0.styles];
var RenderType_CollectedDebtPerWeekComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CollectedDebtPerWeekComponent, data: {} });
export { RenderType_CollectedDebtPerWeekComponent as RenderType_CollectedDebtPerWeekComponent };
export function View_CollectedDebtPerWeekComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Deudas cobradas por semana"])), (_l()(), i1.ɵeld(4, 16777216, null, null, 1, "app-datatable", [], null, null, null, i2.View_DatatableComponent_0, i2.RenderType_DatatableComponent)), i1.ɵdid(5, 245760, null, 0, i3.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i4.BroadcastService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataTableConfig; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_CollectedDebtPerWeekComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-collected-debt-per-week", [], null, null, null, View_CollectedDebtPerWeekComponent_0, RenderType_CollectedDebtPerWeekComponent)), i1.ɵdid(1, 245760, null, 0, i5.CollectedDebtPerWeekComponent, [i6.PermissionsService, i7.ServicesDashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CollectedDebtPerWeekComponentNgFactory = i1.ɵccf("app-collected-debt-per-week", i5.CollectedDebtPerWeekComponent, View_CollectedDebtPerWeekComponent_Host_0, {}, {}, []);
export { CollectedDebtPerWeekComponentNgFactory as CollectedDebtPerWeekComponentNgFactory };
