import { OnDestroy, OnInit } from '@angular/core';
import { ServicesDashboardService } from '../../../../services/services-dashboard.service';
import { FormBuilder } from '@angular/forms';
import { ticketsTypes } from '../../../../constants/tickets';
var TicketsClosedByMonthComponent = /** @class */ (function () {
    function TicketsClosedByMonthComponent(formBuilder, servicesDashboardService) {
        this.formBuilder = formBuilder;
        this.servicesDashboardService = servicesDashboardService;
        this.subscriptions = [];
        this.isLoad = false;
        this.data = {
            title: 'Tickets cerrados por mes',
            description: 'Obtiene los tickets cerrados por mes en un periodo de 1 año',
            chartType: 'bar',
            chartLabels: [],
            chartData: []
        };
        this.form = this.formBuilder.group({
            from: [],
            to: []
        });
    }
    TicketsClosedByMonthComponent.prototype.ngOnInit = function () {
        this.setForm();
        this.getTicketsClosedByMonth();
    };
    TicketsClosedByMonthComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * getTicketsClosedByMonth
     * Obtiene y formatea la información de los tipos de tickets cerrados por mes
     * @param dateRangeParams
     */
    TicketsClosedByMonthComponent.prototype.getTicketsClosedByMonth = function (dateRangeParams) {
        var _this = this;
        this.subscriptions.push(this.servicesDashboardService.ticketsClosedByMonth(dateRangeParams).subscribe(function (resp) {
            if (resp.success) {
                var dataSets = [];
                var responseData = resp.response;
                var axisXLabels = Object.keys(responseData);
                _this.data.chartLabels = axisXLabels;
                for (var monthData in responseData) {
                    var _loop_1 = function (type) {
                        var formatedType = ticketsTypes[type];
                        var dataSet = dataSets.find(function (data) { return data.label === formatedType; });
                        var amount = responseData[monthData][type];
                        if (dataSet) {
                            dataSet.data.push(amount);
                        }
                        else {
                            dataSets.push({ label: formatedType, data: [amount] });
                        }
                    };
                    for (var type in responseData[monthData]) {
                        _loop_1(type);
                    }
                }
                _this.data.chartData = dataSets;
            }
        }));
    };
    TicketsClosedByMonthComponent.prototype.setForm = function () {
        this.fieldFrom = this.form.get('from');
        this.fieldTo = this.form.get('to');
    };
    return TicketsClosedByMonthComponent;
}());
export { TicketsClosedByMonthComponent };
