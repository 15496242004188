import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SharedComponent } from '../../../model/shared-component';
import { BroadcastService } from '../../../services/broadcast.service';
import { ChargesService } from '../../../services/charges.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';
import { ModalComponent } from '../../modal/modal.component';
import { ChargeSummaryComponent } from '../charge-summary/charge-summary.component';
import { HelpersService } from '../../../services/helpers.service';

@Component({
  selector: 'app-cancel-charge-buttons',
  templateUrl: './cancel-charge-buttons.component.html',
  styleUrls: ['./cancel-charge-buttons.component.scss']
})
export class CancelChargeButtonsComponent implements OnInit, OnDestroy {
  @Input() data: any
  activeReference = false;
  lastCardCharge = false;
  activeReferenceInfo: any;
  lastCardChargeInfo: any;
  subscriptions: Array<Subscription> = [];

  constructor(
    public activeModal: NgbActiveModal,
    private readonly appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly swalService: SwalService,
    private readonly clientService: ClientsService,
    private readonly chargesService: ChargesService,
    private readonly helpersService: HelpersService,
  ) { }

  ngOnInit(): void {
    this.checkCurrentPayments(this.data.id_clients);
  }

  
  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
  * showChargeInfo
  * Muestra la informacion del ultimo cargo por tarjeta o referencia en las ultimas 48 hrs del cliente.
  */
  showChargeInfo(chargeType): void {
    const data = chargeType === 'card' ? this.lastCardChargeInfo : this.activeReferenceInfo;
    const formatData = this.helpersService.flatObject(data);
    const props: SharedComponent = new SharedComponent(
      ChargeSummaryComponent,
      formatData,
      { title: `Resumen del cargo de ${this.data.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
  * cancelLastCharge
  * Manda una petición Put a la API para actualizar la columna extradata y setear el status canceled
  * para que las validaciones de la API permitan generar otra referencia
  */
  cancelLastCharge(type: string): void {
    const message = type === 'reference' ? 'Referencia cancelada' : 'Bloqueo removido';
    const chargeInfo = type === 'reference' ? this.activeReferenceInfo : this.lastCardChargeInfo;
    const referenceIdCharge = chargeInfo.response.charge_data.charge_data.id_charges;
    const params = { type };

    if (type === 'reference') {
      Object.assign(params, { id_charges: referenceIdCharge });
    }

    if (type === 'card') {
      Object.assign(params, { id_clients: this.data.id_clients });
    }

    this.subscriptions.push(this.chargesService.cancelLastCharge(params).subscribe((resp: any) => {
      if (resp.success) {
        this.swalService.success({ title: `${message} exitosamente` }).then(() => {
          this.activeModal.dismiss();
          this.broadcast.reloadDataTable();
        });
      } else {
        this.swalService.error({ title: 'Ocurrio un error al momento de cancelar la referencia' });
      }
    }));
  }

  /**
   * checkCurrentPayments
   * Verifica el estado de la ultima referencia (OXXO|SPEI) o 
   * de la tarjeta para verificar si se puede crear una referencia o cargo por tarjeta.
   */
  private checkCurrentPayments(id_client): void {
    this.subscriptions.push(this.clientService.checkLastPayments(id_client).subscribe((last_payments_responses: any) => {
      last_payments_responses.forEach((charge_response, index) => {
        switch (index) {
          case 0:
            if (!charge_response.success) {
              this.activeReference = true;
              this.activeReferenceInfo = charge_response;
            }
            break;
          case 1:
            if (!charge_response.success) {
              this.lastCardCharge = true;
              this.lastCardChargeInfo = charge_response;
            }
            break;
        }
      });
    }));
  }

}
