import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component,
  OnDestroy, OnInit, TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarView, CalendarEvent, CalendarEventAction,
  CalendarEventTimesChangedEvent, CalendarEventTitleFormatter
} from 'angular-calendar';
import {
  startOfDay,
  endOfDay,
  isSameMonth,
  isSameDay,
} from 'date-fns';
import { colors } from '../../../constants/colors';
import { Subject, Subscription } from 'rxjs';
import { CustomEventTitleFormatter } from '../calendar/custom-event-title-formater.provider';
import { TicketsService } from '../../../services/tickets.service';
import { CalendarService } from '../../../services/calendar.service';
import { ticketsTableOptions } from '../../../constants/tickets';
import { BroadcastService } from '../../../services/broadcast.service';
import { environment } from '../../../../environments/environment';
import { ModalComponent } from '../../modal/modal.component';
import { SwalService } from '../../../services/swal.service';
import { SharedComponent } from '../../../model/shared-component';
import { ShowTicketInfoComponent } from '../../../admin/service/show-ticket-info/show-ticket-info.component';


@Component({
  selector: 'app-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnDestroy, OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  locale = 'es-MX';
  dayStartHour = 8;
  dayEndHour = 19;
  weekStartsOn = 1;
  dataTableConfig: any;
  ticketTableOptions = ticketsTableOptions;
  notSupportedEvents = environment.notSupportedEvents;
  subscriptions: Array<Subscription> = [];
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;

  constructor(
    private readonly modal: NgbModal,
    public appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly calendarService: CalendarService,
    private readonly swalService: SwalService,
    public readonly ticketsService: TicketsService,
  ) { }

  ngOnInit(): void {
    this.getAssignedTickets();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  /**
   * eventTimesChanged
   * @param param
   */
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.updateEventTime(event, newStart, newEnd);
  }

  /**
   * eventClicked
   * @param event 
   */
  eventClicked(event): void {
    const component = ShowTicketInfoComponent;
    const props: SharedComponent = new SharedComponent(component, event.data, { title: `Información del evento ${event.title}` });
    this.appModal.opensm(props);
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  /**
   * navigateToDay
   * Renderiza la vista de dia del calendario cuando se selecciona un dia en
   * la vista del mes o el header de la vista de la semana
   * @param date fecha la cual se desea renderear en la vista CalendarView.Day
   */
  navigateToDay(date: Date) {
    this.viewDate = date;
    this.setView(this.CalendarView.Day);
  }


  /**
   * handleEvent
   * Actualiza la fecha y hora del evento al cual se le haya efectuado un evento
   * resize o drag and drop
   * @param event evento a actualizar
   * @param newStart nuevo fecha de inicio
   * @param newEnd nueva fecha de fin
   */
  private updateEventTime(event: CalendarEvent, newStart: Date, newEnd: Date): void {
    const params = {
      type: 'calendar',
      details: event['details'],
      // id_assignations: event['id_assignations'],
      newStart: this.getStringDate(newStart), 
      newEnd: this.getStringDate(newEnd)
    };

    if ((newStart !== event.start) || (newEnd !== event.end)) {
      this.subscriptions.push(
        // this.calendarService.updateOnlyDate(params).subscribe((resp) => {
        //   if (resp.success) {
        //     this.swalService.success();
        //   }
        // })
        this.ticketsService.update(event['id_tickets'], params).subscribe((resp) => {
          if (resp.success) {
            this.swalService.success();
          }
        })
      );
    }
  }

  /**
   * getAssignedTickets
   * Obtiene los tickets con status asignado y añade las acciones para los eventos
   * asignar, desaasignar, marcar como no realizado
   */
  private getAssignedTickets(): void {
    this.subscriptions.push(
      this.calendarService.getAssignedTickets().subscribe(response => {
        if (response.response) {
          this.events = response.response;
          this.events.forEach((ticketEvent) => {
            this.setEventOptions(ticketEvent);
          });
        }
      })
    );
  }

  /**
   * setEventOptions
   * Configura las opciones de los eventos a mostrar
   * @param event de tipo ticket o general
   */
  private setEventOptions(event: CalendarEvent): void {
    event.start = new Date(event.start);
    event.end = new Date(event.end);
    event.draggable = true;
    event.resizable = {
      beforeStart: true,
      afterEnd: true,
    };
  }

  /**
   * getStringDate
   * Convierte el date devuelto por el calendario y lo transforma en un string
   * para enviar al server
   * @param date fecha a transformar
   * @returns string
   */
  private getStringDate(date: Date): string {

    return date.toString().split('(')[0];
  }
}
