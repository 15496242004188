<div class="container-fluid border-bottom bg-white p-3 mb-4">
  <div class="text-right">
    <button class="btn btn-success" (click)="this.ticketsService.createSupportTicketModal()">Crear ticket de
      soporte</button>
  </div>
  <br />
  <div *ngIf="this.events.length > 0; else loading">
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            Anterior
          </div>
          <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
            Hoy
          </div>
          <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            Siguiente
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'): locale }}</h3>
      </div>
      <div class="col-md-4">
        <div class="btn-group">
          <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month">
            Mes
          </div>
          <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
            Semana
          </div>
          <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
            Día
          </div>
        </div>
      </div>
    </div>
    <br />

    <div [ngSwitch]="view">
      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [(viewDate)]="viewDate" [events]="events"
        [refresh]="refresh" [locale]="locale" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
        (eventTimesChanged)="eventTimesChanged($event)" (eventClicked)="eventClicked($event.event)">
      </mwl-calendar-month-view>
      <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [(viewDate)]="viewDate" [events]="events"
        [refresh]="refresh" [locale]="locale" [weekStartsOn]="weekStartsOn"[dayStartHour]="dayStartHour" 
        [dayEndHour]="dayEndHour" (dayHeaderClicked)="navigateToDay($event.day.date)" 
        (eventTimesChanged)="eventTimesChanged($event)" (eventClicked)="eventClicked($event.event)">
      </mwl-calendar-week-view>
      <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [(viewDate)]="viewDate" [events]="events"
        [refresh]="refresh" [locale]="locale" [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour"
        (eventTimesChanged)="eventTimesChanged($event)" (eventClicked)="eventClicked($event.event)">
      </mwl-calendar-day-view>
    </div>

    <br>

    <app-tickets [type]="'opened'"></app-tickets>
  </div>
  
  <ng-template #loading>
    <div class="text-center">
      Cargando eventos...
    </div>
  </ng-template>
</div>