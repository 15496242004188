// Modules
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxPermissionsModule } from 'ngx-permissions';
// Components
import { AdministratorCreateEditComponent } from '../../admin/administration/administrators/administrator-create-edit/administrator-create-edit.component';
import { RoleCreateEditComponent } from '../../admin/administration/roles/role-create-edit/role-create-edit.component';
import { UserCreateEditComponent } from '../../admin/administration/users/user-create-edit/user-create-edit.component';
import { CardFailedHistoryComponent } from '../../admin/clients/client-cards/card-failed-history/card-failed-history.component';
import { ClientChargesHistoryComponent } from '../../admin/clients/client-charges-history/client-charges-history.component';
import { MakeRefundComponent } from '../../admin/clients/client-charges-history/make-refund/make-refund.component';
import { ReviewRefundsComponent } from '../../admin/clients/client-charges-history/review-refunds/review-refunds.component';
import { ClientEditComponent } from '../../admin/clients/client-edit/client-edit.component';
import { ClientHistoryComponent } from '../../admin/clients/client-history/client-history.component';
import { ClientUpdatePlanComponent } from '../../admin/clients/client-update-plan/client-update-plan.component';
import { ContractComponent } from '../../admin/clients/contract/contract.component';
import { ManualPaymentComponent } from '../shared/manual-payment/manual-payment.component';
import { CreateSupportTicketComponent } from '../../admin/clients/create-support-ticket/create-support-ticket.component';
import { CommissionsComponent } from '../../admin/clients/referals/commissions/commissions.component';
import { ReferalsComponent } from '../../admin/clients/referals/referals.component';
import { TreeviewComponent } from '../../admin/clients/referals/treeview/treeview.component';
import { RestorePasswordComponent } from '../../admin/clients/restore-password/restore-password.component';
import { AdvertisingFormComponent } from '../../admin/dashboard/admin-dashboard/advertising-table/advertising-form/advertising-form.component';
import { CashFormComponent } from '../../admin/dashboard/admin-dashboard/cash-table/cash-form/cash-form.component';
import { MoneySpentCampaignFormComponent } from '../../admin/dashboard/admin-dashboard/money-spent-campaign/money-spent-campaign-form/money-spent-campaign-form.component';
import { NpsFormComponent } from '../../admin/dashboard/admin-dashboard/nps-table/nps-form/nps-form.component';
import { SatisfactionFormComponent } from '../../admin/dashboard/admin-dashboard/satisfaction-table/satisfaction-form/satisfaction-form.component';
import { DebtsComponent } from '../../admin/finances/clients/debts/debts.component';
import { ClientInvalidComponent } from '../../admin/sellers/commissions/client-invalid/client-invalid.component';
import { CreateEditCategoryComponent } from '../../admin/sellers/extras/create-edit-category/create-edit-category.component';
import { CreateEditElementComponent } from '../../admin/sellers/extras/create-edit-element/create-edit-element.component';
import { ElementsComponent } from '../../admin/sellers/extras/elements/elements.component';
import { CreateEditGroupComponent } from '../../admin/sellers/sales-group/create-edit-group/create-edit-group.component';
import { CreateEditPlanComponent } from '../../admin/sellers/sales-group/plans/create-edit-plan/create-edit-plan.component';
import { PlansComponent } from '../../admin/sellers/sales-group/plans/plans.component';
import { ErrorCodeCreateEditComponent } from '../../admin/service/error-codes/error-code-create-edit/error-code-create-edit.component';
import { AssignComponent } from '../../admin/service/tickets/assign/assign.component';
import { AssignationCreateEditComponent } from '../../admin/service/tickets/assignation-create-edit/assignation-create-edit.component';
import { CloseComponent } from '../../admin/service/tickets/close/close.component';
import { ReassingComponent } from '../../admin/service/tickets/reassing/reassing.component';
import { RecordComponent } from '../../admin/service/tickets/record/record.component';
import { GalleryCreateEditComponent } from '../../admin/social/gallery/gallery-create-edit/gallery-create-edit.component';
import { EditGroupComponent } from '../../admin/social/notify/edit-group/edit-group.component';
import { MembersGroupComponent } from '../../admin/social/notify/members-group/members-group.component';
import { NewGroupComponent } from '../../admin/social/notify/new-group/new-group.component';
import { SharedComponentDirective } from '../../directives/shared-component.directive';
import { FromService } from '../../providers/form.service';
import { DatatableModule } from '../datatable/datatable.module';
import { FormModule } from '../form/form.module';
import { HistoryModule } from '../history/history.module';
import { CompletionMapComponent } from '../history/items/tickets/fields/completions/completion-map/completion-map.component';
import { PipesModule } from '../pipes/pipes.module';
import { ChargeSummaryComponent } from '../shared/charge-summary/charge-summary.component';
import { ModalBodyComponent } from './modal-body/modal-body.component';
import { ModalComponent } from './modal.component';
import { WithdrawalCreateEditComponent } from '../../admin/finances/withdrawals/withdrawal-create-edit/withdrawal-create-edit.component';
import { PollShowComponent } from '../../admin/social/polls/poll-show/poll-show.component';
import { PollCreateEditComponent } from '../../admin/social/polls/poll-create-edit/poll-create-edit.component';
import { PollAssignComponent } from '../../admin/social/polls/poll-assign/poll-assign.component';
import { ClientCreateComponent } from '../../admin/clients/client-create/client-create.component';
import { ClientCardsComponent } from '../../admin/clients/client-cards/client-cards.component';
import { CardCreateComponent } from '../../admin/clients/client-cards/card-create/card-create.component';
import { ClientCreditsComponent } from '../../admin/clients/client-credits/client-credits.component';
import { CreateCreditComponent } from '../../admin/clients/client-credits/create-credit/create-credit.component';
import { CreditHistoryComponent } from '../../admin/clients/client-credits/credit-history/credit-history.component';
import { UpdateAcceptedClientsSalesGroupComponent } from '../../admin/clients/update-accepted-clients-sales-group/update-accepted-clients-sales-group.component';
import { UnrealizedComponent } from '../../admin/service/tickets/unrealized/unrealized.component';
import { CreateEditPromotionalMonthlyFeeComponent } from '../../admin/sellers/promotional-monthly-fees/create-edit-promotional-monthly-fee/create-edit-promotional-monthly-fee.component';
import { ClientPromotionalsMonthlyFeesComponent } from '../../admin/clients/client-promotionals-monthly-fees/client-promotionals-monthly-fees.component';
import { AssociateChildAccountsComponent } from '../../admin/clients/associate-child-accounts/associate-child-accounts.component';
import { SuppliesCategoriesCreateEditComponent } from '../../admin/warehouse/supplies-categories/supplies-categories-create-edit/supplies-categories-create-edit.component';
import { SupplyCreateEditComponent } from '../../admin/warehouse/supplies/supply-create-edit/supply-create-edit.component';
import { SupplyInputCreateComponent } from '../../admin/warehouse/supplies/supply-input-create/supply-input-create.component';
import { SupplyRecordComponent } from '../../admin/warehouse/supply-record/supply-record.component';
import { UniquePaymentComponent } from '../shared/unique-payment/unique-payment.component';
import { PollResultsComponent } from '../../admin/social/polls/poll-results/poll-results.component';
import { PollRankingComponent } from '../../admin/social/polls/poll-ranking/poll-ranking.component';
import { UploadPhotosComponent } from '../shared/upload-photos/upload-photos.component';
import { ClientExtraImagesComponent } from '../../admin/clients/client-extra-images/client-extra-images.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SharedModule } from '../shared/shared.module';
import { ShowTicketInfoComponent } from '../../admin/service/show-ticket-info/show-ticket-info.component';
import { TicketsActionsComponent } from '../../admin/service/tickets-actions/tickets-actions.component';

const modalBodys = [
  ErrorCodeCreateEditComponent,
  RecordComponent,
  AssignComponent,
  ReassingComponent,
  CloseComponent,
  ContractComponent,
  ManualPaymentComponent,
  ClientEditComponent,
  ClientChargesHistoryComponent,
  ClientHistoryComponent,
  ReferalsComponent,
  MakeRefundComponent,
  ReviewRefundsComponent,
  RestorePasswordComponent,
  CreateSupportTicketComponent,
  AdministratorCreateEditComponent,
  ReferalsComponent,
  CreateEditGroupComponent,
  DebtsComponent,
  ElementsComponent,
  CreateEditCategoryComponent,
  CreateEditElementComponent,
  ClientInvalidComponent,
  NewGroupComponent,
  EditGroupComponent,
  MembersGroupComponent,
  CommissionsComponent,
  TreeviewComponent,
  ChargeSummaryComponent,
  AssignationCreateEditComponent,
  CashFormComponent,
  SatisfactionFormComponent,
  NpsFormComponent,
  MoneySpentCampaignFormComponent,
  CardFailedHistoryComponent,
  AdvertisingFormComponent,
  GalleryCreateEditComponent,
  UserCreateEditComponent,
  RoleCreateEditComponent,
  PlansComponent,
  CreateEditPlanComponent,
  CompletionMapComponent,
  ClientUpdatePlanComponent,
  WithdrawalCreateEditComponent,
  PollShowComponent,
  PollCreateEditComponent,
  PollAssignComponent,
  PollResultsComponent,
  PollRankingComponent,
  ClientCreateComponent,
  ClientCardsComponent,
  CardCreateComponent,
  ClientCreditsComponent,
  CreateCreditComponent,
  CreditHistoryComponent,
  UpdateAcceptedClientsSalesGroupComponent,
  UnrealizedComponent,
  CreateEditPromotionalMonthlyFeeComponent,
  ClientPromotionalsMonthlyFeesComponent,
  AssociateChildAccountsComponent,
  SuppliesCategoriesCreateEditComponent,
  SupplyCreateEditComponent,
  SupplyInputCreateComponent,
  SupplyRecordComponent,
  UniquePaymentComponent,
  UploadPhotosComponent,
  ClientExtraImagesComponent,
  ShowTicketInfoComponent,
  TicketsActionsComponent
];

@NgModule({
  declarations: [
    ModalComponent,
    ModalBodyComponent,
    SharedComponentDirective,
    ...modalBodys
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    // DataTablesModule,
    DatatableModule,
    FormModule,
    HistoryModule,
    NgxLoadingModule,
    PipesModule,
    NgxPermissionsModule.forChild(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBq6jUoU6vrft-0hVUjkcAyeeM4zSuahuo'
      // libraries: ['places']
    }),
    PaginationModule.forRoot(),
    SharedModule
  ],
  entryComponents: [
    ModalComponent,
    ModalBodyComponent,
    ...modalBodys
  ],
  exports: [
    ModalComponent
  ],
  providers: [
    ModalComponent,
    NgbActiveModal,
    FromService
  ],
  bootstrap: []
})
export class ModalModule { }
