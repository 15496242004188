import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../components/modal/modal.component';
import { UploadPhotosComponent } from '../../../components/shared/upload-photos/upload-photos.component';
import { SharedComponent } from '../../../model/shared-component';
import { BroadcastService } from '../../../services/broadcast.service';
import { SwalService } from '../../../services/swal.service';
import { AssignComponent } from '../tickets/assign/assign.component';
import { AssignationCreateEditComponent } from '../tickets/assignation-create-edit/assignation-create-edit.component';
import { CloseComponent } from '../tickets/close/close.component';
import { RecordComponent } from '../tickets/record/record.component';
import { UnrealizedComponent } from '../tickets/unrealized/unrealized.component';
import { TicketsService } from '../../../services/tickets.service';

@Component({
  selector: 'app-tickets-actions',
  templateUrl: './tickets-actions.component.html',
  styleUrls: ['./tickets-actions.component.scss']
})
export class TicketsActionsComponent implements OnInit {
  @Input() data: any;

  constructor(
    public appModal: ModalComponent,
    public activeModal: NgbActiveModal,
    private ticketsService: TicketsService,
    private readonly broadcast: BroadcastService,
    private readonly swalService: SwalService,
  ) { }

  ngOnInit(): void {
    //
  }

  /**
    * editTicketModal
    * Abre un modal para editar la fecha - hora de realizacion de un ticket o la
    * descripción sin necesidad de reasignar el ticket
    * @param data informacion del ticket
    */
  editTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignationCreateEditComponent,
      { ticket: data, status: 'edit' },
      { title: `Edición del ticket #${data.id_tickets}` }
    );
    this.appModal.opensm(props);
  }

  /**
   * assignTicketModal
   * Abre un modal para assignar un ticket a un tecnico
   * @param data 
   */
  assignTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignComponent,
      { ticket: data, status: 'assign' },
      { title: `Asignación de ticket ${data.id_tickets}` }
    );
    this.appModal.openXl(props);
  }

  /**
   * reassignTicket Modal
   * Abre un modal para reasignar el ticket a otro tecnico
   * @param data 
   */
  reassingTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignComponent,
      { ticket: data, status: 'reassing' },
      { title: `Reasignación de ticket #${data.id_tickets}` }
    );
    this.appModal.openXl(props);
  }

  /**
   * unassignTicketModal
   * Abre un modal para marcar como desaasignado un ticket
   * @param data informacion del ticket
   */
  unassignTicketModal(data): void {
    const swalParams = {
      title: '¿Desea desasignar este ticket?',
      text: '¡Si desasigna este ticket el estatus pasara a abierto!'
    };

    this.swalService.warning(swalParams).then(result => {
      if (result.value) {
        const params = {
          status: 'unassigned'
        };
        this.ticketsService.setStatus(params, data.id_tickets).subscribe((res: any) => {
          if (res.success) {
            this.swalService.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error({ title: 'Ocurrió un error al actualizar los datos' });
          }
        });
      }
    });
  }

  /**
   * closeTicketModal
   * Abre un modal para ingresar los datos de cerrado del ticket por parte del staff
   * @param data informacion del ticket
   */
  closeTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(CloseComponent, data, { title: `Cerrar el ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.opensm(props);
  }

  /**
    * unrealizedTicketModal
    * Manda una peticion a la API para marcar un ticket como no realizado.
    * @param data  información del ticket
    */
  unrealizedTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      UnrealizedComponent,
      data,
      { title: `Marcar como no realizado el ticket ${data.id_tickets} del cliente ${data.client.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
    * uploadPhotosTicketModal
    * Abre un modal para subir imagenes que se asociaran al ticket
    * @param data 
    */
  uploadPhotosTicketModal(data): void {
    const props: SharedComponent = new SharedComponent(
      UploadPhotosComponent,
      data,
      { title: `Subir imagenes al ticket ${data.id_tickets} del cliente ${data.client.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
   * recordModal
   * Abre un modal para visualizar el historial de un ticket
   * @param data informacion del ticket 
   */
  recordModal(data): void {
    const props: SharedComponent = new SharedComponent(RecordComponent, data, { title: `Historial del ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.openXl(props);
  }

}
