import { Component, Input, OnInit } from '@angular/core';
import { assignation, technician } from '../../../model/services.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-ticket-info',
  templateUrl: './show-ticket-info.component.html',
  styleUrls: ['./show-ticket-info.component.scss']
})
export class ShowTicketInfoComponent implements OnInit {
  @Input() data: any;
  assignation: assignation;
  technician: technician;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.setData();
  }

  private setData(): void {
    this.assignation = this.data.assignations[0];
    this.technician = this.assignation.technician;
  }

}
