<div class="row text-center">
    <div class="col-sm-12 col-md-4">
        <button type="button" class="btn btn-outline-dark" (click)="editTicketModal(data)">Editar</button>
    </div>
    <div class="col-sm-12 col-md-4">
        <button type="button" class="btn btn-outline-dark" (click)="reassingTicketModal(data)">Reasignar</button>
    </div>
    <div class="col-sm-12 col-md-4">
        <button type="button" class="btn btn-outline-dark" (click)="unassignTicketModal(data)">Desasignar</button>
    </div>
</div>
