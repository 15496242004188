/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tickets-closed-by-month.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/shared/charts/charts.component.ngfactory";
import * as i3 from "../../../../components/shared/charts/charts.component";
import * as i4 from "../../../../services/swal.service";
import * as i5 from "@angular/common";
import * as i6 from "./tickets-closed-by-month.component";
import * as i7 from "@angular/forms";
import * as i8 from "../../../../services/services-dashboard.service";
var styles_TicketsClosedByMonthComponent = [i0.styles];
var RenderType_TicketsClosedByMonthComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketsClosedByMonthComponent, data: {} });
export { RenderType_TicketsClosedByMonthComponent as RenderType_TicketsClosedByMonthComponent };
function View_TicketsClosedByMonthComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-charts", [], null, null, null, i2.View_ChartsComponent_0, i2.RenderType_ChartsComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChartsComponent, [i4.SwalService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TicketsClosedByMonthComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TicketsClosedByMonthComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.data == null) ? null : _co.data.chartData.length) > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TicketsClosedByMonthComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tickets-closed-by-month", [], null, null, null, View_TicketsClosedByMonthComponent_0, RenderType_TicketsClosedByMonthComponent)), i1.ɵdid(1, 245760, null, 0, i6.TicketsClosedByMonthComponent, [i7.FormBuilder, i8.ServicesDashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TicketsClosedByMonthComponentNgFactory = i1.ɵccf("app-tickets-closed-by-month", i6.TicketsClosedByMonthComponent, View_TicketsClosedByMonthComponent_Host_0, {}, {}, []);
export { TicketsClosedByMonthComponentNgFactory as TicketsClosedByMonthComponentNgFactory };
