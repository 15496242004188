import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { chartParams } from '../../../../model/shared-component.interface';
import { ServicesDashboardService } from '../../../../services/services-dashboard.service';

@Component({
  selector: 'app-closed-tickets-per-day',
  templateUrl: './closed-tickets-per-day.component.html',
  styleUrls: ['./closed-tickets-per-day.component.scss']
})
export class ClosedTicketsPerDayComponent implements OnInit {
  subscriptions: Array<Subscription> = [];
  isLoad = false;
  data: chartParams = {
    title: 'Tickets cerrados por dia',
    description: 'Obtiene los porcentajes de los tickets cerrados en 1, 3 y 5 dias en un periodo de un mes',
    chartType: 'pie',
    chartLabels: [],
    chartData: []
  };
  constructor(
    private readonly servicesDashboardService: ServicesDashboardService
  ) { }

  ngOnInit(): void {
    this.closedTicketsPerDays();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * closedTicketsPerDays
   * Obtiene el porcentaje de tickets cerrados por dia
   */
  private closedTicketsPerDays(): void {
    this.subscriptions.push(
      this.servicesDashboardService.closedTicketsPerDays().subscribe((resp: any) => {
        if (resp.success) {
          const responseData = resp.response;
          const labels = Object.keys(responseData);
          const dataSet = [{ data: Object.values(responseData) }];
          this.data.chartLabels = labels;
          this.data.chartData = dataSet;
        }
      })
    );
  }

}
