import { OnDestroy, OnInit } from '@angular/core';
import { TicketsService } from '../../../services/tickets.service';
import { SharedComponent } from '../../../model/shared-component';
import { AssignComponent } from '../tickets/assign/assign.component';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SwalService } from '../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from '../../../services/broadcast.service';
import { FormBuilder } from '@angular/forms';
import { FromService } from '../../../providers/form.service';
import { UsersService } from '../../../services/users.service';
import { ClientsService } from '../../../services/clients.service';
import { UnrealizedComponent } from '../tickets/unrealized/unrealized.component';
import { HelpersService } from '../../../services/helpers.service';
var TicketsMapComponent = /** @class */ (function () {
    function TicketsMapComponent(broadcast, formBuilder, fromService, clientsService, ticketService, usersService, appModal, swal, helpersService, activeModal) {
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.clientsService = clientsService;
        this.ticketService = ticketService;
        this.usersService = usersService;
        this.appModal = appModal;
        this.swal = swal;
        this.helpersService = helpersService;
        this.activeModal = activeModal;
        this.tickets = [];
        this.subscriptions = [];
        this.show_filters = false;
        this.executed = false;
        this.simulateTickets = false;
        this.button = {
            icon: 'fa fa-filter',
            style: 'btn btn-success'
        };
        this.clients = [];
        this.technicians = [];
        this.tickets_types = [];
        this.tickets_status = [
            { id: 'assigned', name: 'Asignado' },
            { id: 'opened', name: 'Abierto' }
        ];
        this.form = this.formBuilder.group({
            id_clients: [],
            status: [],
            type: [],
            technician: []
        });
        this.lat = 20.673135;
        this.lng = -103.350693;
        this.zoom = 4;
        this.warehouse = {
            name: 'Bodega',
            icon: 'assets/img/tickets_status/warehouse-marker.png',
            lat: 20.600148598973142,
            lng: -103.43025483863697
        };
    }
    TicketsMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'reload-map':
                    if (event.data !== 'filtered') {
                        _this.getMapTickets();
                    }
                    break;
            }
        }));
        this.getDefaultView();
    };
    TicketsMapComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * clickedMarker
     * Abre un modal con la informacion del marcador seleccionado en el mapa
     * @param infowindow parametros del marcador seleccionado que se encuentran asociados a un ticket
     */
    TicketsMapComponent.prototype.clickedMarker = function (infowindow) {
        if (this.previous) {
            this.previous.close();
        }
        this.previous = infowindow;
    };
    /**
     * ticketStatusUrl
     * Obtiene la URL de la imagen a mostrar en el marcador dependiendo del status del ticket
     * @param status del ticket
     * @param type indica el tipo de ticket
     * @returns string que es la url de la imagen que se le va asociar al marcador.
     */
    TicketsMapComponent.prototype.ticketStatusUrl = function (ticket) {
        var premiumPlans = [2, 4];
        var isPremium = premiumPlans.includes(ticket.client.id_plan_type);
        var serviceCalls = ['removal', 'move'];
        var type = serviceCalls.includes(ticket.type) ? 'service_call' : ticket.type;
        var status = ticket.status;
        var folder = 'assets/img/tickets_status';
        var marker = type === 'simulated' ? type + "-marker" : status + "-marker-" + type;
        var url = folder + "/" + marker;
        if (isPremium) {
            url += '-p';
        }
        url += '.png';
        return url;
    };
    /**
     * assignTicketModal
     * Abre el modal para asignar un ticket abierto a un tecnico.
     * @param data Objeto con la información del ticket
     */
    TicketsMapComponent.prototype.assignTicketModal = function (data) {
        Object.assign(data, { isMap: true });
        var props = new SharedComponent(AssignComponent, { ticket: data, status: 'assign' }, { title: 'Asignación de ticket' });
        this.appModal.openXl(props);
    };
    /**
     * reassingTicketModal
     * Abre el modal para reasignar un ticket asignado a un tecnico.
     * @param data Objeto con la información del ticket
     */
    TicketsMapComponent.prototype.reassingTicketModal = function (data) {
        Object.assign(data, { isMap: true });
        var props = new SharedComponent(AssignComponent, { ticket: data, status: 'reassing' }, { title: 'Reasignación de ticket' });
        this.appModal.openXl(props);
    };
    /**
     * unassignTicketModal
     * Abre el modal para desasignar un ticket asignado a un tecnico.
     * @param data Objeto con la información del ticket
     */
    TicketsMapComponent.prototype.unassignTicketModal = function (data) {
        var _this = this;
        var swalParams = {
            title: '¿Desea desasignar este ticket?',
            text: '¡Si desasigna este ticket el estatus pasara a abierto!'
        };
        this.swal.warning(swalParams).then(function (result) {
            if (result.value) {
                var params = {
                    status: 'unassigned'
                };
                _this.subscriptions.push(_this.ticketService.setStatus(params, data.id_tickets).subscribe(function (respData) {
                    if (respData.success) {
                        _this.swal.success().then(function () { _this.getMapTickets(); });
                    }
                    else {
                        _this.swal.error({ title: 'Ocurrió un error al actualizar los datos' });
                    }
                }));
            }
        });
    };
    /**
    * unrealizedTicketModal
    * Abre el modal para marcar un ticket como no realizado.
    * @param data Objeto con la información del ticket
    */
    TicketsMapComponent.prototype.unrealizedTicketModal = function (data) {
        Object.assign(data, { isMap: true });
        var props = new SharedComponent(UnrealizedComponent, data, { title: "Marcar como no realizado el ticket " + data.id_tickets + " del cliente " + data.client.name });
        this.appModal.opensm(props);
    };
    /**
     * ButtonStatus
     * Define los parametros a mostrar en el boton del filtrado dependiendo si se muestran o no
     * los elementos de los filtros
     */
    TicketsMapComponent.prototype.buttonStatus = function () {
        this.show_filters = !this.show_filters ? true : false;
        this.button.icon = this.show_filters ? 'fa fa-window-close' : 'fa fa-filter';
        this.button.style = this.show_filters ? 'btn btn-danger' : 'btn btn-success';
        if (!this.executed) {
            this.loadData();
        }
    };
    /**
     * filterMap
     * Filtra el mapa de tickets dependiendo de los parametros que se le pasaron.
     */
    TicketsMapComponent.prototype.filterMap = function () {
        var _this = this;
        var filterValues = this.fromService.getDirtyValues(this.form);
        this.subscriptions.push(this.ticketService.getMapTickets(filterValues).subscribe(function (resp) {
            if (resp.data.tickets.length > 0) {
                _this.broadcast.fire({
                    name: 'reload-map',
                    data: 'filtered'
                });
                _this.tickets = resp.data.tickets;
            }
            else {
                _this.swal.warning({ text: 'No se encontraron datos' });
            }
        }));
    };
    /**
     * getAssignedTechnicians
     * Obtiene la ultima asignación y tecnico asociado a un ticket
     * @param assignations Array con todas las asignaciones que ha tenido el ticket
     * @returns Objeto con la informacion de la assignacion y el tecnico
     */
    TicketsMapComponent.prototype.getAssignedTechnician = function (assignations) {
        var assignedTechnician;
        if (assignations && assignations.length) {
            var assigned = assignations.slice();
            assigned.sort(function (a, b) { return (a.updated_at < b.updated_at) ? 1 : -1; });
            assignedTechnician = {
                name: assigned[0].technician.name,
                email: assigned[0].technician.email,
                assignationDate: assigned[0].updated_at
            };
        }
        return assignedTechnician;
    };
    /**
     * getTicketsNextMonth
     * Obtiene la simulación de tickets del proximo mes para mostrarlos en el mapa
     */
    TicketsMapComponent.prototype.getTicketsNextMonth = function () {
        var _this = this;
        this.subscriptions.push(this.ticketService.simTicketsNextMonth().subscribe(function (resp) {
            var _a;
            (_a = _this.tickets).push.apply(_a, resp.data.tickets);
            _this.simulateTickets = true;
        }));
    };
    TicketsMapComponent.prototype.copyToClipboard = function (text) {
        this.helpersService.copyToClipboard(text);
    };
    /**
     * getMapTickets
     * Obtiene la lista de tickets abiertos o asignados.
     */
    TicketsMapComponent.prototype.getMapTickets = function () {
        var _this = this;
        this.ticketService.getMapTickets().subscribe(function (resp) {
            _this.tickets = resp.data.tickets;
            _this.simulateTickets = false;
        });
    };
    /**
     * getDefaultView
     * Obtiene las coordenadas en donde se centrara el mapa de tickets inicialmente
     * esto dependiendo del team_id del usuario logueado
     */
    TicketsMapComponent.prototype.getDefaultView = function () {
        var _this = this;
        this.subscriptions.push(this.ticketService.getMapTickets().subscribe(function (resp) {
            _this.tickets = resp.data.tickets;
        }));
    };
    /**
     * loadData
     * Carga los valores de los filtros cuando el boton de filtrado es usado
     * este proceso solo se hace una vez.
     */
    TicketsMapComponent.prototype.loadData = function () {
        this.getTicketsTypes();
        this.getActiveTechnicians();
        this.getActiveClients();
        this.fromService.setForm(this.form);
        this.executed = true;
    };
    /**
     * getTicketsTypes
     * Obtiene los tipos de tickets que admite el sistema
     */
    TicketsMapComponent.prototype.getTicketsTypes = function () {
        var _this = this;
        this.subscriptions.push(this.ticketService.getTicketTypes().subscribe(function (resp) {
            if (resp.success) {
                _this.tickets_types = resp.response;
            }
            else {
                _this.swal.error({ text: 'No se pudieron obtener los tipos de tickets' });
            }
        }));
    };
    /**
     * getActiveTechnicias
     * Obtiene el ARRAY con los tecnicos activos en el sistema
     */
    TicketsMapComponent.prototype.getActiveTechnicians = function () {
        var _this = this;
        this.subscriptions.push(this.usersService.getUserByRole({ status: 1 }, { role: { role: 'technician' } }).subscribe(function (resp) {
            _this.technicians = resp.data;
        }));
    };
    /**
     * getActiveClients
     * Obtiene la lista de todos los clientes activos en el sistema
     * TODO: mejorar la obtencion de esta información ya que de momento se traen a todos los clientes
     * lo ideal seria traerlos de la API conforme se va ingresando el nombre.
     */
    TicketsMapComponent.prototype.getActiveClients = function () {
        var _this = this;
        var params = {
            selected_columns: ['id_clients', 'name'],
            status: 'accepted',
            length: '3000'
        };
        this.subscriptions.push(this.clientsService.getClients(params).subscribe(function (resp) {
            if (resp.data.length > 0) {
                _this.clients = resp.data.map(function (client) { return ({ id: client.id_clients, name: client.name }); });
            }
        }));
    };
    return TicketsMapComponent;
}());
export { TicketsMapComponent };
