/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./closed-tickets-per-day.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/shared/charts/charts.component.ngfactory";
import * as i3 from "../../../../components/shared/charts/charts.component";
import * as i4 from "../../../../services/swal.service";
import * as i5 from "@angular/common";
import * as i6 from "./closed-tickets-per-day.component";
import * as i7 from "../../../../services/services-dashboard.service";
var styles_ClosedTicketsPerDayComponent = [i0.styles];
var RenderType_ClosedTicketsPerDayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClosedTicketsPerDayComponent, data: {} });
export { RenderType_ClosedTicketsPerDayComponent as RenderType_ClosedTicketsPerDayComponent };
function View_ClosedTicketsPerDayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-charts", [], null, null, null, i2.View_ChartsComponent_0, i2.RenderType_ChartsComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChartsComponent, [i4.SwalService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ClosedTicketsPerDayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClosedTicketsPerDayComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.data == null) ? null : _co.data.chartData.length) > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ClosedTicketsPerDayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-closed-tickets-per-day", [], null, null, null, View_ClosedTicketsPerDayComponent_0, RenderType_ClosedTicketsPerDayComponent)), i1.ɵdid(1, 245760, null, 0, i6.ClosedTicketsPerDayComponent, [i7.ServicesDashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClosedTicketsPerDayComponentNgFactory = i1.ɵccf("app-closed-tickets-per-day", i6.ClosedTicketsPerDayComponent, View_ClosedTicketsPerDayComponent_Host_0, {}, {}, []);
export { ClosedTicketsPerDayComponentNgFactory as ClosedTicketsPerDayComponentNgFactory };
