import * as moment from 'moment';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { SwalService } from '../../../../services/swal.service';
import { TicketsService } from '../../../../services/tickets.service';
import { ChargesService } from '../../../../services/charges.service';
import { HelpersService } from '../../../../services/helpers.service';

@Component({
  selector: 'app-close',
  templateUrl: './close.component.html',
  styleUrls: ['./close.component.scss']
})
export class CloseComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  payment_types = environment.payment_types;
  payment = false;
  show_serial_number = false;
  message: any;
  offline_format: any;
  credit_applied = 0;
  credit_remaining_amount = 0;
  whatsAppMessage: string = '';
  form: FormGroup = this.formBuilder.group({
    final_service_fee: [0],
    final_service_fee_reasons: ['', Validators.required],
    showSerialNumber: [false]
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly broadcast: BroadcastService,
    public activeModal: NgbActiveModal,
    private readonly tickets: TicketsService,
    private readonly swal: SwalService,
    private readonly fromService: FromService,
    private readonly chargesService: ChargesService,
    private readonly helpersService: HelpersService,
    private readonly cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'tickets-technitians-row-click': this.message = event.data; break;
      }
    }
    ));
    this.credit_remaining_amount = this.creditRemainingAmount(this.data.client.active_credit);
    this.fromService.setForm(this.form);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
    this.subscriptions.push(this.form.get('final_service_fee').valueChanges.subscribe(value => {
      const paymentTypesControl = this.form.get('payment_types');
      if (value > 0) {
        this.payment = true;
        if (!paymentTypesControl) {
          this.form.addControl('payment_types', new FormControl('', Validators.required));
        }
      } else {
        this.payment = false;
        if (paymentTypesControl) {
          this.form.removeControl('payment_types');
        }
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * closeTicket
   * Envia una petición a la API para cerrar completamente un ticket
   */
  closeTicket(): void {
    const close_info = {
      swal_params: {
        swal_title: 'Monto igual a 0, no se realizara ningún cargo al cliente',
        text: '¿Estás seguro de cerrar el ticket?'
      },
      params: {
        status: 'closed',
        final_service_fee: this.form.controls.final_service_fee.value,
        final_service_fee_reasons: this.form.controls.final_service_fee_reasons.value,
        used_credit: false
      }
    };

    if (this.form.get('serial_number')) {
      Object.assign(close_info.params, { serial_number: this.form.get('serial_number').value });
    }

    if (this.form.get('payment_types')) {
      Object.assign(close_info.params, { payment_types: this.form.get('payment_types').value });
    }
    if (this.form.controls.final_service_fee.value > 0) {
      let total = this.form.controls.final_service_fee.value;
      if (this.credit_remaining_amount > 0) {
        const originalTotal = total;
        const totalWithCredit = total - this.credit_remaining_amount;
        total = (totalWithCredit > 0 && totalWithCredit <= 10) ? total : totalWithCredit;

        this.credit_applied = (total < originalTotal && total > 10) ? (originalTotal - totalWithCredit) : 0;

        if (this.credit_applied > 0) {
          close_info.params.used_credit = true;
        }
      }

      total = total < 0 ? 0 : total;
      close_info.swal_params.swal_title = `Se efecutara un cargo al cliente por el monto de $ ${total} pesos`;
    }

    this.swal.warning(
      { title: close_info.swal_params.swal_title, text: close_info.swal_params.text }
    ).then(result => {
      if (result.value) {
        this.subscriptions.push(this.tickets.setStatus(close_info.params, this.data.id_tickets).subscribe((data: any) => {
          if (data.success) {
            if (close_info.params.final_service_fee > 0) {
              this.showSuccessModal(this.form.get('payment_types').value, data);
            } else {
              this.swal.success({ text: 'Ticket cerrado exitosamente' }).then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            }
          } else {
            this.swal.error({ title: 'Ocurrió un error al actualizar los datos' });
          }
        }));
      }
    });
  }

  /**
   * updateSerialNumber
   * Añade el campo para actualizar el número de serie.
   * @param event determina si el checkbox esta marcado o no
   */
  updateSerialNumber(event): void {
    const serial_number_form = this.form.get('serial_number');
    if (event.checked) {
      this.show_serial_number = true;
      const serial_number = this.data.client.serial_number;
      if (!serial_number_form) {
        this.form.addControl('serial_number', new FormControl('', Validators.required));
        this.form.get('serial_number').setValue(serial_number);
      }
    } else {
      this.show_serial_number = false;
      if (serial_number_form) {
        this.form.removeControl('serial_number');
      }
    }
  }

  /**
   * formatMessage
   * Genera un objeto con la info que mostrara el modal cuando se reciba la confirmación de la creación de una referencia en la API
   * esta info se mostrara mientras la referencia no expire.
   * @param resp respuesta devuelta por la API con la info de la referencia creada
   * @param payment_type tipo de referencia que se generara OXXO/SPEI
   * @returns objeto que el titulo y el mensaje que llevara el modal
   */

  formatMessage(resp, payment_type): any {
    let offline_msg = '<div>';
    let title_offline = '';
    const oxxo_message = 'Referencia generada exitosamente';
    const spei_message = 'CLABE generada exitosamente';
    const payment_info = resp.response.data.charges.data[0].payment_method;
    const expiration_date = moment(payment_info.expires_at * 1000).format('MMMM Do YYYY, h:mm:ss a');

    switch (payment_type) {
      case 'OXXO':
        offline_msg += `<a>${payment_info.reference}<\a>`;
        title_offline = oxxo_message;
        break;
      case 'SPEI':
        offline_msg = `<a>${payment_info.clabe}<\a>`;
        title_offline = spei_message;
        break;
    }
    offline_msg += '<br> <br>';
    offline_msg += '<a><strong>Fecha de expiración: </strong></a>';
    offline_msg += `<a>${expiration_date}<\a>`;
    offline_msg += '<\div>';

    return { title: title_offline, msg: offline_msg };
  }

  /**
   * creditRemainingAmount
   * Verifica si el cliente tiene credito valido para hacer un descuento
   * @param activeCredit objeto con la informacion de un credito valido.
   * @returns number con la funcion
   */
  creditRemainingAmount(activeCredit): number {
    let creditRemainingAmount = 0;
    if (activeCredit) {
      if (activeCredit.status && activeCredit.remaining_amount > 100) {
        creditRemainingAmount = activeCredit.remaining_amount;
      }
    }

    return creditRemainingAmount / 100;
  }

  sendByWhatsApp() {
    this.helpersService.sendByWhatsapp(this.whatsAppMessage, this.data.client.phone);
  }

  /**
   * showSuccessModal
   * Muestra el modal de exito cuando una referencia OXXO|SPEI o cargo por tarjeta es efectuado exitosamente.
   * @param paymentType tipo de cargo que se realizo Tarjeta|OXXO|SPEI
   * @param resp unicamente se usa si el cargo fue un OXXO|SPEI para mostra el modal de la información de la referencia.
   */
  private showSuccessModal(paymentType, resp?): void {
    if (paymentType === 'CARD') {
      this.swal.success({ title: 'Transación efectuada exitosamente' }).then(() => {
        this.activeModal.dismiss();
        this.broadcast.reloadDataTable();
      });
    } else {
      const whatsAppButton = `<button type="button" class="btn btn-success" id="sendByWa" title="Enviar por Whatsapp">
                                <i class="fa fa-whatsapp"></i>
                              </button>`;
      this.offline_format = this.chargesService.formatMessage(resp, paymentType);
      this.whatsAppMessage = this.offline_format.copyText;

      const data = {
        title: this.offline_format.title,
        html: this.offline_format.msg + whatsAppButton,
        onBeforeOpen: () => {
          const sendByWa = document.getElementById('sendByWa');
          sendByWa.addEventListener('click', () => {
            this.sendByWhatsApp();
          });
        }
      };
      this.swal.success(data).then(() => {
        this.activeModal.dismiss();
        this.broadcast.reloadDataTable();
      });
    }
  }

  /**
   * validToUpdateSerialNumber
   * Determina si se mostrara el checkbox para actualizar el numero de serie de
   * un equipo asociado a un cliente.
   */
  get validToUpdateSerialNumber(): boolean {
    const typesInvalidForUpdate = ['installation', 'removal'];
    const isValid = !typesInvalidForUpdate.includes(this.data.type);

    return isValid;
  }
}
